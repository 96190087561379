<template>
  <div v-if="!errorFlag">
    <div
      v-if="level == 'C1' || level == 'B1' || level == 'B2'"
      class="page_one"
    >
      <div class="level_title self_">
        <span v-if="this.level=='KET'" class="B2">A2 Key</span>
        <span v-else class="B2">Level {{ level }}</span>
        <span class="content">
          <span>
            {{ titleList.questionDetail }}
          </span>
        </span>
      </div>
      <!-- 第一题 -->
      <div v-for="(item, index) in titleList.questionList" :key="item.id">
        <div class="level_title topic">
          <span class="B2 topic_B2">{{ index + 1 }}</span>
          <span class="content topic_con"></span>
        </div>
        <div class="anwser">
          <li
            class="level_title anwser_tit"
            @click="handleAnswerSelect(item, index, 'A')"
            :class="{ active: answer[index] === 'A' }"
          >
            <span class="B2 anwsers_">A</span>
            <span class="anwsers_con">{{ item.optionAText }}</span>
          </li>

          <li
            class="level_title anwser_tit"
            @click="handleAnswerSelect(item, index, 'B')"
            :class="{ active: answer[index] === 'B' }"
          >
            <span class="B2 anwsers_">B</span>
            <span class="anwsers_con">{{ item.optionBText }}</span>
          </li>
          <li
            class="level_title anwser_tit"
            @click="handleAnswerSelect(item, index, 'C')"
            :class="{ active: answer[index] === 'C' }"
          >
            <span class="B2 anwsers_">C</span>
            <span class="anwsers_con">{{ item.optionCText }}</span>
          </li>
        </div>
      </div>
    </div>

    <!--  -->
    <div v-else class="page_one">
      <div class="level_title self_">
        <span v-if="this.level=='KET'" class="B2">A2 Key</span>
        <span v-else class="B2">Level {{ level }}</span>
        <span class="content">
          <span>
            {{ titleList.questionDetail }}
          </span>
        </span>
      </div>
      <!-- 第一题 -->
      <div
        class="paperImg"
        v-for="(item, index) in titleList.questionList"
        :key="index"
      >
        <div class="level_title topic">
          <span class="B2 topic_B2">{{ index + 1 }}</span>
          <span class="content topic_con">{{ item.optionATex }}</span>
        </div>
        <div class="anwser">
          <li
            class="anwser_img"
            @click="handleAnswerSelect(item, index, 'A')"
            :class="{ actives: answer[index] === 'A' }"
          >
            <div class="image"><img :class="zhouheng?'zhouheng':''" :src="item.optionAUrl" alt="" /></div>
            <span class="B2 anwsers_">A</span>
          </li>

          <li
            class="anwser_img"
            @click="handleAnswerSelect(item, index, 'B')"
            :class="{ actives: answer[index] === 'B' }"
          >
            <div class="image"><img :class="zhouheng?'zhouheng':''" :src="item.optionBUrl" alt="" /></div>
            <span class="B2 anwsers_">B</span>
          </li>
          <li
            class="anwser_img"
            @click="handleAnswerSelect(item, index, 'C')"
            :class="{ actives: answer[index] === 'C' }"
          >
            <div class="image"><img :class="zhouheng?'zhouheng':''" :src="item.optionCUrl" alt="" /></div>
            <span class="B2 anwsers_">C</span>
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PaperOne",
  data() {
    return {
      Level: "C",
      flag: false,
      titleList: {
        questionList: [
          {
            haveOptionsImg: "",
            haveOptionsText: "",
            haveQuestionText: "",
            id: "",
            optionAText: "",
            optionAUrl: "",
            optionBText: "",
            optionBUrl: "",
            optionCText: "",
            optionCUrl: "",
            optionsNumber: "",
            questionText: "",
          },
        ],
      },
      answer: {}, // 用于存储用户的答案
      answerList: [],
      errorFlag:true,
      zhouheng:false

    };
  },
  mounted() {
    // console.log(this.$store.state.exam.data);
    this.getPaperInfo();
    this.flag = false;
    setTimeout(() => {
      this.flag = true;
    }, 1);
    setTimeout(()=>{
      this.errorFlag = false
    },500)
    this.$nextTick(()=>{
      this.zhouheng = true
    })
  },
  methods: {
    getPaperInfo() {
      let result = this.paperData;
      // console.log(result);
      result.listeningP1.questionList.forEach((item, i) => {
        this.answerList[i] = "";
      });
      this.titleList = result.listeningP1;
      // console.log(this.titleList,111);
    },

    handleAnswerSelect(item, index, answer) {
      this.activeIndex = index;

      this.$set(this.answer,index, answer);

    },
  },
  computed: {
    ...mapState({
      paperData: (state) => state.home.data,
      level: (state) => state.home.level,
    }),
  },
  watch: {
    // 收集答案
    paperData() {
      this.getPaperInfo();
    },
    answer: {
      handler(newVal, oldVal) {
        for (let info in newVal) {
   
        //   console.log(newVal);
   
          // 判断字段是否为空
          if (newVal[info]) {
            // console.log(info,"newVal[info]");
            // 若不为空则将数据按id顺序存入数组
            this.answerList[info] =
              newVal[info];
          } else {
            // 若为空则将数据转变为空字符串后存入数组
            this.answerList[info] = "";
          }
        }
        // console.log(answerList);
        
        let answers = [...this.answerList];
        console.log(answers);
        
        this.$store.dispatch("getAnswerLisOne", answers);
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page_one {
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
}

.self_ {
  margin-bottom: 30px;
}
.level_title {
  width: 100%;
  height: 50px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    height: 50px;
    color: snow;
    text-align: center;
  }

  .B2 {
    box-sizing: border-box;

    width: 10%;
    line-height: 50px;
    background-color: rgb(240, 54, 54);
  }

  .topic_B2 {
    background-color: rgb(51, 51, 51);
    font-size: 20px;
  }

  .content {
    box-sizing: border-box;
    padding: 0 30px;
    width: 90%;
    background-color: rgb(51, 51, 51);
    display: flex;
    align-items: center;
    // justify-content: center;
    box-sizing: border-box;
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: start;
    }
  }

  .topic_con {
    background-color: rgb(166, 166, 166);
  }
}

div {
  margin-top: 25px;
  .anwser {
    padding-left: 5%;
    margin-top: 25px;
    .anwser_tit {
      height: 35px;
      margin-top: 10px;
      .anwsers_ {
        font-size: 20px;
        width: 5.4%;
        height: 35px;
        line-height: 35px;
        background-color: rgb(11, 11, 11);
      }
      .anwsers_con {
        height: 35px;
        line-height: 35px;
        color: black;
        background-color: rgb(255, 254, 254);
        margin-left: 30px;
      }
    }
  }
}

.paperImg {
  margin-top: 25px;
  .topic_con {
    justify-content: start;
  }
  .anwser {
    padding-left: 0px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .anwser_img {
      width: 250px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;


        img {
          width: 200px;


          // height: 100%;
        }
        // 延迟动画
        .zhouheng{
          animation: anImg 0.6s;
        }
      
    }
    // .anwser_img:hover{
    //     transition: 1s;
    //     scale: 1.5;

    // }
    .anwsers_ {
      //   border: 1px solid rgb(122, 121, 121);
      margin-top: 10px;
      display: block;
      width: 45px;
      height: 35px;
      color: rgb(249, 242, 242);
      font-size: 20px;
      line-height: 35px;
      text-align: center;
      background-color: rgb(51, 51, 51);
    }
  }
}

.active {
  border: 1px solid red;
  .anwsers_ {
    background: red !important;
    color: snow !important;
  }
}
.actives {
  .anwsers_ {
    background: red !important;
    color: snow !important;
  }
}
.fill {
  width: 99.9%;
}
@keyframes anImg {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>