import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';
//使用插件
Vue.use(VueRouter);
//引入路由组件
import routes from './routes';

let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function(location,resolve,reject){
    if(resolve && reject){
        originPush.call(this,location,resolve,reject)
    }else{
        originPush.call(this,location,()=>{},()=>{});
    }
}

VueRouter.prototype.replace = function(location,resolve,reject){
    if(resolve && reject){
        originReplace.call(this,location,resolve,reject)
    }else{
        originReplace.call(this,location,()=>{},()=>{});
    }
}

let router = new VueRouter({
    routes,
    
    //滚动行为
    scrollBehavior(to,from,savedPosition){
        //返回的y=0代表滚动条在最上方
        return {x : 0, y : 0}
    }
});

// 路由守卫配置
// 1.考试页面只有在home路由下才能跳转至exam里面
// meta.requiresAuth 路由元信息，设置true代表需要验证，登录用户的类型是0管理员才可以进去
router.beforeEach((to, from, next) => {
let userType = localStorage.getItem('userType')
   // 判断只有管理员才能进入的页面 
    if(to.meta.requiresAuth=='admin' && userType!=='0' ){
       next('/login')
    }else if(to.meta.requiresAuth=='teacher' && userType!=='1' ){
        next('/login')
     }else if(to.meta.requiresAuth=='student' && userType!=='2' ){
        next('/login')
     }
    // else if(userType!=='2'&&to.name == 'home'){
    //     // 如果不是考生则不能跳转到home页面
    //     next(from.fullPath)
    // }
    // else if(userType =='2'&&to.name == 'home'||to.name == 'confirm'||to.name == 'nda'||to.name == 'examInstruction'||to.name == 'paperTwo'||to.name == 'correct'||to.name == 'nda'){
    //     // 判断是管理员的情况下不能跳到哪里
    //     next(from.fullPath)
      
    // }   
    else{
        // exam路由必须是从考试说明页面进去的
        let requiresFromInstruction = to.meta.requiresFromInstruction
        if(requiresFromInstruction && requiresFromInstruction !== from.fullPath){
            next('/home')
        }
        next()
    }

})


export default router;