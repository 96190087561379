<template>
    <div class="swiper">
        <div v-if="level == 'C1' || level == 'B1' || level == 'B2'" class="page_one">
            <div class="level_title self_">
                <span class="B2">Level {{ level }}</span>
                <span class="content">
                    <span>
                        {{ listeningP4.questionDetail }}
                    </span>
                </span>
            </div>

            <!-- 第一题 -->
            <div v-for="(item, index) in listeningP4.questionList" :key="item.id">
                <div class="level_title topic">
                    <span class="B2 topic_B2">{{ index + 1 }}</span>
                    <span class="content topic_con">{{ item.questionText }}</span>
                </div>
                <div class="anwser">
                    <li class="level_title anwser_tit" @click="handleAnswerSelect(item, index, 'A')"
                        :class="{ selected_: answers[index] == 'A' }">
                        <span class="B2 anwsers_" :class="{ spa: answers[index] == 'A' }">A</span>
                        <span class="anwsers_con">{{ item.optionAText }}</span>
                    </li>

                    <li class="level_title anwser_tit" @click="handleAnswerSelect(item, index, 'B')"
                        :class="{ selected_: answers[index] == 'B' }">
                        <span class="B2 anwsers_" :class="{ spa: answers[index] == 'B' }">B</span>
                        <span class="anwsers_con">{{ item.optionBText }}</span>
                    </li>
                    <li class="level_title anwser_tit" @click="handleAnswerSelect(item, index, 'C')"
                        :class="{ selected_: answers[index] == 'C' }">
                        <span class="B2 anwsers_" :class="{ spa: answers[index] == 'C' }">C</span>
                        <span class="anwsers_con">{{ item.optionCText }}</span>
                    </li>
                </div>
            </div>
            <!-- <button class="finish_btn" @click="submit">Finish</button> -->
        </div>

        <!-- <div class="level_title self_ information">
          <span class="content">
            <span>
             information
            </span>
          </span>
        </div> -->

        <!-- A2 -->
        <div v-if="level == 'A2'">
            <div class="blank_">
                <div class="level_title self_">
                    <span class="B2">Level {{ level }}</span>
                    <span class="content">
                        <span>
                            {{ listeningP4.questionDetail }}
                        </span>
                    </span>
                </div>
                <div class="level_title self_ information" style="">
                    <span class="content">
                        <span class="single" style="font-size:20px"> {{ listeningP4.title }}</span>
                    </span>
                </div>
                <!-- 第一题 -->
                <div v-for="(item, index) in listeningP4.questionList" :key="item.id" class="topic level_title">
                    <div class="question">
                        <span class="B2 question_sort">{{ index + 1 }}</span>
                        <span class="question_tit">{{ item.questionText }}</span>
                    </div>
                    <div class="anwser">
                        <li class="anwser_blank">
                            <input type="text" placeholder="Please enter there" :maxlength="200"
                                v-model="listeningP4Blank[index]" />
                        </li>
                    </div>
                </div>

            </div>
        </div>

        <!-- A1 -->
        <div v-if="level == 'A1'" class="page_one L4_two">
            <div class="level_title self_">
                <span class="B2">Level A1</span>
                <span class="content">
                    <span>
                        {{ listeningP4.questionDetail }}
                    </span>
                </span>
            </div>
            <div class="level_title self_ information">
                <span class="content">
                    <span class="single"> {{ listeningP4.title }}</span>
                </span>
            </div>
            <!-- 第一题 -->
            <div v-for="(item, index) in listeningP4.questionList" :key="item.id" class="topic level_title"
                style="border:none">
                <div class="question">
                    <span class="B2 question_sort">{{ index + 1 }}</span>
                    <span class="question_tit">{{ item.questionText }}</span>
                </div>
                <div class="anwser">
                    <li class="anwser_blank" @click="handleAnswerSelect_two(item, index, $event)"
                        :class="{ selected_L2: answers_[index] == item.optionAText }">
                        <span>{{ item.optionAText }}</span>
                    </li>
                    <li class="anwser_blank" @click="handleAnswerSelect_two(item, index, $event)"
                        :class="{ selected_L2: answers_[index] == item.optionBText }">
                        <span>{{ item.optionBText }}</span>
                    </li>
                    <li class="anwser_blank" @click="handleAnswerSelect_two(item, index, $event)"
                        :class="{ selected_L2: answers_[index] == item.optionCText }">
                        <span>{{ item.optionCText }}</span>
                    </li>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "PaperListen4",
    data() {
        return {
            Level: "",
            listeningP4: {
                haveTitle: "",
                id: "",
                questionDetail: "",
                questionList: [],
                questionNumber: "",
                title: null,
            },

            currentTopic: 1, // 设置当前题目为 id 为 1 的题目
            answers: {}, // 用于存储用户的答案
            answers_: {},  // 收集的是字符串类型
            answerList: [],
            listeningP4Blank: [],
        };
    },
    mounted() {
        this.getPaperInfo();
    },
    methods: {
        getPaperInfo() {

            let result = this.paperData.listeningP4;
            this.listeningP4 = result;

            this.listeningP4.questionList.forEach((item, i) => {
                this.answerList[i] = ''
                this.listeningP4Blank[i] = "";
            })

        },

        handleAnswerSelect(item, index, answer) {
            // 在answers对象中存储该题目的答案选项
            // 直接在数组里面添加数据不是响应式的，通过$set设置的数据才是响应式数据
            this.$set(this.answers, index, answer);
        },
        handleAnswerSelect_two(item, index, event) {
            const text = event.target.innerText
            // console.log(text)
            this.$set(this.answers_, index, text);
        }
    },

    computed: {
        ...mapState({
            paperData: (state) => state.home.data,
            level: (state) => state.home.level,
        }),
    },
    watch: {
        // 收集答案
        answers_: {

            handler(newVal, oldVal) {
                setTimeout(() => {// console.log(this.answers);
                    for (let info in newVal) {
                        if (newVal[info]) {
                            // console.log(newVal[info]);
                            this.answerList[info] = newVal[info]
                        } else {
                            this.answerList[info] = ''
                        }
                    }
                    // console.log(answerList);
                    let answer = [...this.answerList]
                    this.$store.dispatch('getAnswerLisFour', answer)
                    console.log(answer);
                }, 0)

            },
            deep: true

        },
        answers: {

            handler(newVal, oldVal) {
                this.listeningP4.questionList.forEach((item, i) => {
                    this.answerList[i] = ''
                })
                // console.log(this.answers);
                for (let info in newVal) {
                    if (newVal[info]) {
                        // console.log(newVal[info]);
                        this.answerList[info] = newVal[info]
                    } else {
                        this.answerList[info] = ''
                    }
                }
                // console.log(answerList);
                let answer = [...this.answerList]
                this.$store.dispatch('getAnswerLisFour', answer)
                console.log(answer);
            },
            deep: true

        },
        listeningP4Blank: {

            handler(newVal, oldVal) {
                setTimeout(() => {
                    let anwser = [...this.listeningP4Blank]
                    anwser.forEach((item, i) => {
                        if (item == '') {
                            anwser[i] = '^'
                        }
                    })
                    // console.log(anwser);
                    this.$store.dispatch("getAnswerLisFour", anwser);
                }, 100)


            },
            deep: true,
            immediate: true
        }

    }
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
    text-decoration: none;
}

.page_one {
    width: 100%;
    margin: 0 auto;
}

.self_ {
    margin-bottom: 30px;
}

.level_title {
    width: 100%;
    height: 50px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: start;
}

.level_title span {
    height: 50px;
    color: snow;
    text-align: center;
}

.level_title .B2 {
    box-sizing: border-box;

    width: 10%;
    line-height: 50px;
    background-color: #f03636;
}

.level_title .topic_B2 {
    box-sizing: border-box;
    background-color: #333333;
    font-size: 20px;
}

.level_title .content {
    box-sizing: border-box;
    padding: 0 30px;
    width: 90%;
    background-color: #333333;
    display: flex;
    align-items: center;
    justify-content: start;
}

.level_title .content span {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-align: start;
}

.level_title .topic_con {
    background-color: #a6a6a6;
    font-size: 14px;
}

div {
    margin-top: 25px;
}

div .anwser {
    padding-left: 5%;
    margin-top: 25px;
}

div .anwser .anwser_tit {
    height: 35px;
    margin-top: 10px;
}

div .anwser .anwser_tit .anwsers_ {
    font-size: 20px;
    width: 5.4%;
    height: 35px;
    line-height: 35px;
    background-color: #151414;
    border: none;
}

div .anwser .anwser_tit .anwsers_con {
    height: 35px;
    line-height: 35px;
    color: black;
    background-color: #fffefe;
    margin-left: 30px;
}

.selected_ {
    border: 1px solid red;
}

.spa {
    display: block;
    background: #f03636 !important;
}

.information {
    width: 60%;
    margin: 0 auto;
    font-weight: bold;
    // border: 1px solid rgb(212, 212, 217);
    border: none;

    .content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(252, 252, 255);
        letter-spacing: 2px;

        span {
            font-size: 24px;
            color: black;
        }
    }
}

//  填空题的样式

.topic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //   border: none;
    margin-bottom: 25px;

    .question {
        width: 45%;
        background-color: rgb(51, 51, 51);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: start;

        .question_sort {
            width: 26%;
            font-size: 20px;
            background-color: rgb(51, 51, 51);
        }

        .question_tit {
            font-size: 16px;
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: start;
            background-color: rgb(166, 166, 166);
            padding-left: 30px;
        }
    }

    .anwser {
        width: 45%;
        height: 100%;
        background-color: rgb(51, 51, 51);

        .anwser_blank {
            width: 100%;
            height: 100%;
            font-size: 14px;

        }
    }
}

.L4_two {
    .level_title {
        .anwser {
            width: 50%;

            background: #fffefe;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .anwser_blank {
                width: 30%;
                line-height: 50px;
            }

            span {
                height: 35px;
                margin-top: 7.5px;
                background: rgb(21, 20, 20);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

// 点击后添加的样式
.selected_L2 {
    span {
        background: #f03636 !important;

    }
}


// 
.blank_ {
    width: 100%;
    margin: 0 auto;

    .self_ {
        margin-bottom: 30px;
        margin-top: 25px;
    }

    .level_title {
        width: 100%;
        height: 50px;
        // border: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .level_title span {
        height: 50px;
        // color: snow;
        text-align: center;
    }

    .level_title .B2 {
        width: 10%;
        line-height: 50px;
        background-color: #f03636;
    }

    .level_title .content {
        padding: 0 30px;
        box-sizing: border-box;
        width: 90%;
        // background-color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .level_title .content span {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-align: start;
    }

    .topic {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        margin-bottom: 25px;
    }

    .topic .question {
        width: 45%;
        // background-color: #333333;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .topic .question .question_sort {
        width: 26%;
        font-size: 20px;
        background-color: #333333;
    }

    .topic .question .question_tit {
        font-size: 14px;
        width: 80%;
        display: flex;
        align-items: center;
        // justify-content: center;
        background-color: #a6a6a6;
    }

    .topic .anwser {
        width: 45%;
        height: 100%;
        background-color: rgb(252, 252, 255);
    }

    .topic .anwser .anwser_blank {
        width: 100%;
        height: 100%;
    }

    .topic .anwser .anwser_blank input {
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 18px;
        display: inline-block;
        width: 100%;
        height: 100%;
    }
}

input {
    padding-left: 20px;
    box-sizing: border-box;
    font-size: 14px !important;
    display: inline-block;
    width: 100%;
    height: 100%;
}
</style>