<template>
    <div class="swiper">

        <!-- 试卷等级为B1，B2，C1的样式 -->
        <div v-if="level == 'KET'">
            <div class="blank_">
                <div class="level_title self_">
                    <span class="B2">A2 Key</span>
                    <span class="content">
                        <span>
                            {{ listeningP3.questionDetail }}
                        </span>
                    </span>
                </div>
                <!-- 二级标题 -->
                <!-- <div class="level_title self_ information" style="">
                    <span class="content">
                        <span class="single"> {{ listeningP3.title }}</span>
                    </span>
                </div> -->
                <!-- 选项 -->
                <div class="select_item">
                    <span><b>A</b> boring</span>
                    <span><b>B</b> full of surprises </span>
                    <span><b>C</b> not clear</span>
                    <span><b>D</b> not very scary </span>
                    <span><b>E</b> really funny </span>
                    <span><b>F</b> really popular </span>
                    <span><b>G</b> too strange</span>
                    <span><b>H</b> useful</span>
                </div>
                <!-- 例子 -->
                <span style="display: inline-block; margin-bottom: 20px"> <b> Example: </b></span> 

                <div  class="topic level_title">
                    <div class="question">
                        <span class="B2 question_sort">0</span>
                        <span class="question_tit start_" style="text-align: start">Kids Comedy</span>
                    </div>
                    <div class="anwser">
                        <li class="anwser_blank">
                            <input type="text"  :value="'E'"  />
                        </li>
                    </div>
                </div>
                <!-- 第一题 -->
                <span style="display: inline-block; margin-bottom: 20px"> <b> TV programmes </b></span> 
                <span style="display: inline-block; margin-bottom: 20px;margin-left: 42%;"> <b> What Jon says </b></span> 
                <div v-for="(item, index) in listeningP3.questionList" :key="item.id" class="topic level_title">
                    <div class="question">
                        <span class="B2 question_sort">{{ index + 21 }}</span>
                        <span class="question_tit start_" style="text-align: start">{{
            item.questionText
        }}</span>
                    </div>
                    <div class="anwser">
                        <li class="anwser_blank">
                            <input type="text" placeholder="Please enter A~H" v-model="listeningP3Blank[index]"
                                :maxlength="200" />
                        </li>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "PaperListen3",
    data() {
        return {
            Level: "C", // 用来判断
            listeningP3: {
                haveTitle: "",
                id: "",
                questionDetail: "",
                questionList: [],
                questionNumber: "",
                title: null,
            },

            currentTopic: 1, // 设置当前题目为 id 为 1 的题目
            answers: {}, // 用于存储用户的答案

            // 填空题类型收集的答案
            listeningP3Blank: [],
            answerList: [],
        };
    },
    beforeUpdated() {
        this.getPaperInfo();
    },
    mounted() {
        this.getPaperInfo();
    },
    methods: {
        getPaperInfo() {

            let result = this.paperData.listeningP4;
            this.listeningP3 = result;
            this.listeningP3.questionList.forEach((item, i) => {
                this.listeningP3Blank[i] = "";
                this.answerList[i] = "";
            });

        },

        handleAnswerSelect(item, index, answer) {
            // 在answers对象中存储该题目的答案选项
            // 直接在数组里面添加数据不是响应式的，通过$set设置的数据才是响应式数据
            this.$set(this.answers, index, answer);
        },
        submit() {
            // console.log(this.answers);
        },
    },

    computed: {
        ...mapState({
            paperData: (state) => state.home.data,
            level: (state) => state.home.level,
        }),
    },
    watch: {
        // 收集答案
        answers: {
            handler(newVal, oldVal) {
                // let answerList = [];

                // console.log(this.answers);
                for (let info in newVal) {
                    if (newVal[info]) {
                        // console.log(newVal[info]);
                        this.answerList[info] =
                            newVal[info];
                    } else {
                        this.answerList[info] = "";
                    }
                }
                //  console.log(answerList);
                let anwser = [...this.answerList];
                this.$store.dispatch("getAnswerLisFour", anwser);
                console.log(anwser)
            },
            deep: true,
        },
        //
        listeningP3Blank: {
            handler(newVal, oldVal) {

                setTimeout(() => {
                    let anwser = [];
                    this.listeningP3.questionList.forEach((item, i) => {
                        anwser[i] = "";
                    });
                    // console.log(this.listeningP3Blank);
                    this.listeningP3Blank.forEach((item, i) => {
                        // console.log(item);
                        if (item != "") {
                            anwser[i] = item;
                        }
                    });
                    this.$store.dispatch("getAnswerLisFour", anwser);
                }, 100)


            },
            deep: true,
            immediate: true
        },
    },
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
    text-decoration: none;
}

.page_one {
    width: 100%;
    margin: 0 auto;

    .self_ {
        margin-bottom: 30px;
    }

    .level_title {
        width: 100%;
        height: 50px;
        border: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .level_title span {
        height: 50px;
        color: snow;
        text-align: center;
    }

    .level_title .B2 {
        box-sizing: border-box;
        width: 10%;
        line-height: 50px;
        background-color: #f03636;
    }

    .level_title .topic_B2 {
        font-size: 20px;

        box-sizing: border-box;
        background-color: #333333;
    }

    .level_title .content {
        box-sizing: border-box;
        padding: 0 30px;
        width: 90%;
        background-color: #333333;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .level_title .content span {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-align: start;
    }

    .level_title .topic_con {
        background-color: #a6a6a6;
        font-size: 14px;
    }


    div {
        margin-top: 25px;
    }

    div .anwser {
        padding-left: 5%;
        margin-top: 25px;
    }

    div .anwser .anwser_tit {
        height: 35px;
        margin-top: 10px;
    }

    div .anwser .anwser_tit .anwsers_ {
        //   width: 45px;
        width: 5.4%;
        height: 35px;
        line-height: 35px;
        background-color: #151414;
        border: none;
        font-size: 20px;
    }

    div .anwser .anwser_tit .anwsers_con {
        height: 35px;
        line-height: 35px;
        color: black;
        // background-color: #fffefe;
        margin-left: 30px;
    }

    .selected_ {
        border: 1px solid red;
    }

    .spa {
        display: block;
        background: #f03636 !important;
    }
}

.blank_ {
    width: 100%;
    margin: 0 auto;

    .self_ {
        margin-bottom: 30px;
        margin-top: 25px;
    }

    .level_title {
        width: 100%;
        height: 50px;
        border: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .level_title span {
        height: 50px;
        color: snow;
        text-align: center;
    }

    .level_title .B2 {
        width: 10%;
        line-height: 50px;
        background-color: #f03636;
    }

    .level_title .content {
        padding: 0 30px;
        box-sizing: border-box;
        width: 90%;
        background-color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .level_title .content span {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-align: start;
    }

    .select_item {
        span {
            display: inline-block;
            border: 1px solid gray;
            margin: 0px 20px 20px 0;
            padding: 10px 20px;
        }
    }

    .information {
        width: 100%;
        margin: 20px auto;
        font-weight: bold;
        // border: 1px solid rgb(212, 212, 217);
        border: none;

        .content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgb(252, 252, 255);
            letter-spacing: 2px;

            span {
                font-size: 24px;
                color: black;
            }
        }
    }

    .topic {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        margin-bottom: 25px;
    }

    .topic .question {
        width: 45%;
        background-color: #333333;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: start;
        border: 1px solid gray;
        box-sizing: border-box;
    }

    .topic .question .question_sort {
        width: 25.6%;
        font-size: 20px;
        background-color: #333333;
    }

    .topic .question .question_tit {
        font-size: 14px;
        width: 80%;
        display: flex;
        align-items: center;
        // justify-content: center;
        padding-left: 30px;
        background-color: #a6a6a6;
    }

    .topic .anwser {
        width: 45%;
        height: 100%;
        background-color: #333333;
    }

    .topic .anwser .anwser_blank {
        width: 100%;
        height: 100%;
    }

    .topic .anwser .anwser_blank input {
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 14px;

        display: inline-block;
        width: 100%;
        height: 100%;
    }
}
</style>