import {getPaperInfo} from '@/api'
//state：存储数据的地方
const state = {
    key:JSON.parse(sessionStorage.getItem('Stucode')),
    data:{},
    level:JSON.parse(sessionStorage.getItem('Stulevel')),
};
//mutations：修改state的唯一手段
const mutations = {
    KEYVALUE(state,key){
        state.key = key
    },
    LEVEL(state,level){
        state.level = level
    },
    GETPAPERINFOTWO(state,data){
        state.data = data
    }
}
//actions：处理actions，可以书写自己的业务逻辑，也可以处理异步
const actions = {
    async keyValue({commit},key){

            commit('KEYVALUE',key)
         
    },
    async level({commit},level){
        commit('LEVEL',level)
    },
    async getPaperInfoTWO({commit},data){
        commit('GETPAPERINFOTWO',data)
    }
}
//getters：计算属性，用于简化仓库数组，让组件获取仓库数组更加方便
const getters = {}
export default {
    state,
    mutations,
    actions,
    getters
}