//state：存储数据的地方
const state = {
    data: {},
    answerLisOne: [],
    answerLisTwo:[],
    answerLisThree:[],
    answerLisFour:[],
    answerRedOne:[],
    answerRedTwo:[],
    answerRedThree:[],
    answerRedFour:[],
    answerWitOne:'',
    answerWitTwo:''
};
//mutations：修改state的唯一手段
const mutations = {
    GETPAPERINFO(state, data) {
        state.data = data
    },
    GETANSWERLISONE(state, answerLisOne) {
        state.answerLisOne = answerLisOne
    },
    GETANSWERLISTWO(state,  answerLisTwo) {
        state.answerLisTwo = answerLisTwo
    },
    GETANSWERLISTHREE(state, answerLisThree) {
        state.answerLisThree = answerLisThree
    },
    GETANSWERLISFOUR(state, answerLisFour) {
        state.answerLisFour = answerLisFour
    }, 
    GETANSWERREDONE(state, answerRedOne) {
        state.answerRedOne = answerRedOne
    },
    GETANSWERREDTWO(state, answerRedTwo) {
        state.answerRedTwo = answerRedTwo
    },
    GETANSWERREDTHREE(state, answerRedThree) {
        state.answerRedThree = answerRedThree
    },
    GETANSWERREDFOUR(state, answerRedFour) {
        state.answerRedFour = answerRedFour
    },
    GETANSWERWITONE(state, answerWitOne) {
        state.answerWitOne = answerWitOne
    }, 
    GETANSWERWITTWO(state, answerWitTwo) {
        state.answerWitTwo = answerWitTwo
    }
}
//actions：处理actions，可以书写自己的业务逻辑，也可以处理异步
const actions = {
    getPaperInfo({ commit }, data) {
        commit('GETPAPERINFO', data)
    },
    getAnswerLisOne({ commit }, answerLisOne) {
        commit('GETANSWERLISONE', answerLisOne)
    },
    getAnswerLisTwo({ commit }, answerLisTwo) {
        commit('GETANSWERLISTWO', answerLisTwo)
    },
    getAnswerLisThree({ commit }, answerLisThree) {
        commit('GETANSWERLISTHREE', answerLisThree)
    },
    getAnswerLisFour({ commit }, answerLisFour) {
        commit('GETANSWERLISFOUR', answerLisFour)
    },
    getAnswerRedOne({ commit }, answerRedOne) {
        commit('GETANSWERREDONE', answerRedOne)
    },
    getAnswerRedTwo({ commit }, answerRedTwo) {
        commit('GETANSWERREDTWO', answerRedTwo)
    },
    getAnswerRedThree({ commit }, answerRedThree) {
        commit('GETANSWERREDTHREE', answerRedThree)
    },
    getAnswerRedFour({ commit }, answerRedFour) {
        commit('GETANSWERREDFOUR', answerRedFour)
    },
    getAnswerWitOne({ commit }, answerWitOne) {
        commit('GETANSWERWITONE', answerWitOne)
    },
    getAnswerWitTwo({ commit }, answerWitTwo) {
        commit('GETANSWERWITTWO', answerWitTwo)
    },
}
//getters：计算属性，用于简化仓库数组，让组件获取仓库数组更加方便
const getters = {
}
export default {
    state,
    mutations,
    actions,
    getters
}