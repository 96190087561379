<template>
  <div class="page_one">
    <div class="level_title self_">
      <span class="B2">A2 Key</span>
      <span class="content">
        <span>
          {{titleList.questionDetail }}
        </span>
      </span>
    </div>
    <div class="contain">
      <div class="left">
        <h2>{{ articleTitle }}</h2>
        <span
          v-html="textDetile[0]"></span
        ><br /><br />
      </div>
      <div class="right">
        <div class="item"  v-for="(item, index) in titleList.questionList" :key="index">
          <div class="title">
            <div>{{index+1}}</div>
            <span>{{item.questionText}}</span>
          </div>
          <ul>
            <li  @click="handleAnswerSelect(item,index, 'A')"
            :class="{ active: answer[index] === 'A' }">
              <div style="font-size:20px;">A</div>
              <span>{{ item.optionAText }}</span>
            </li>
            <li @click="handleAnswerSelect(item,index, 'B')"
            :class="{ active: answer[index] === 'B' }">
              <div style="font-size:20px;">B</div>
              <span>{{ item.optionBText }}</span>
            </li>
            <li @click="handleAnswerSelect(item,index, 'C')"
            :class="{ active: answer[index] === 'C' }">
              <div style="font-size:20px;">C</div>
              <span>{{ item.optionCText }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PaperReading2New",
  data() {
    return {
       flag: 0,
      titleList: {},
      article: "",
      text: "",
      textDetile: [],
      articleTitle: "",
      // 试卷答案
      answerList: [],
      answer: {},
    }
  },
  mounted() {
    this.getPaperInfo()
  },
  methods: {
    select(i){
      this.flag = i
    },
    // 获取试卷信息
    getPaperInfo() {
      setTimeout(() => {
        let result = this.paperData;
        this.titleList = result.readingP2;
        this.titleList.questionList.forEach((item,i)=>{
          this.answerList[i] = ''
        })
        this.articleTitle = this.titleList.article.split("#")[0];
        this.article = this.titleList.article.split("#")[1].split("^");
        this.article.forEach((item, i) => {
            if (i >= 0 && i < this.article.length) {
                        let index = i + 1
                        if (index != this.article.length) {
                            this.text += item + "(" + index + ")" + "____";
                        }else{
                            this.text += item 
                        }
                    }
          // console.log(this.text);
          this.textDetile = this.text.split("$");
        });
        // console.log(this.articleTitle,this.textDetile );
      }, 1000);
    },
    handleAnswerSelect(item,index,answer){
        this.activeIndex = index;
        this.$set(this.answer, index, answer);
        // console.log(this.answer)
    }
  },
  watch:{
    // 收集答案
    answer:{
      
      handler(newVal, oldVal) {
        
        // console.log(this.answers);
            for(let info in newVal){
              if(newVal[info]){
                // console.log(newVal[info]);
                this.answerList[info] = newVal[info]
              }else{
                this.answerList[info] = ''
              }
            }
            // console.log(answerList);
            let answers = [...this.answerList]
            this.$store.dispatch('getAnswerRedTwo',answers)
            console.log(answers);

        },
        deep: true

    },
    paperData(){
      this.getPaperInfo()
    }
  },
  computed:{
     ...mapState({
      paperData: (state) => state.home.data,
      level:(state)=>state.home.level,
    }),
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page_one {
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
}

.self_ {
  margin-bottom: 30px;
}
.level_title {
  width: 100%;
  height: 50px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    height: 50px;
    color: snow;
    text-align: center;
  }

  .B2 {
    width: 10%;
    line-height: 50px;
    background-color: rgb(240, 54, 54);
  }

  .topic_B2 {
    background-color: rgb(51, 51, 51);
    font-size: 20px;
  }

  .content {
    padding: 0 30px;
    width: 90%;
    background-color: rgb(51, 51, 51);
    display: flex;
    align-items: center;

    box-sizing: border-box;
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: start;
    }
  }

  .topic_con {
    background-color: rgb(166, 166, 166);
  }
}

.contain {
  display: flex;
  justify-content: space-between;
  .left {
    width: 55%;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    height: 800px;
     overflow: scroll;
    h2 {
      text-align: center;
      font-size: 14px;
      margin: 20px 0;
    }
    span {
      font-size: 14px;
      line-height: 24px;
    }
  }
.right {
    width: 43%;
    height: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    padding: 10px;
    box-sizing: border-box;
    overflow: scroll;
    height: 800px;
    .item {
      width: 95%;
      height: 260px;

      .title {
        display: flex;
        div {
          width: 60px;
          height: 50px;
          background-color: #333;
          color: #fff;
          font-size: 18px;
          line-height: 50px;
          text-align: center;
        }
        span {
          flex: 1;
          background-color: #a6a6a6;
          height: 50px;
          color: #fff;
          box-sizing: border-box;
          padding: 5px;
          font-size: 14px;
          display: flex;
          align-items: center;
        }
      }
      ul {
        height: 250px;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        gap: 20px;
        li {
          display: flex;
          width: 100%;

          height: 40px;
          box-sizing: border-box;
          padding-left: 30px;

          div {
            width: 50px;
            background-color: #333;
            color: #fff;
            font-size: 18px;
            line-height: 40px;
            text-align: center;
          }
          span {
            flex: 1;
            border: 1px solid #000;
            border-left: none;
            box-sizing: border-box;
            padding-left: 10px;

            font-size: 14px;
            display: flex;
            align-items: center;
          }
        }
        .active{
              box-sizing: border-box;
              div{
                background-color: rgb(240, 54, 54);
                
              }
              span{
                background-color: #fff2f2;
                border: 1px solid rgb(240, 54, 54);
              }
            }
      }
    }
  }
}
</style>