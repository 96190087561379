import ExamPaper from '@/views/ExamPaper'
export default [
    {
        path: '/home',
        component: () => import("@/views/Home"),
        meta:{requiresAuth: 'student'},
        name: 'home'
    },
    {
        path: '/login',
        component: () => import("@/views/Login"),
        meta:{requiresAuth: 'any'},
        name: 'login'
    },
    {
        path: '/register',
        component: () => import("@/views/Register"),
        meta:{requiresAuth: 'any'},
        name: 'register'
    },
    {
        path: '/confirm',
        component: () => import("@/views/ConfirmMsg"),
        meta:{requiresAuth: 'student'},
        name: 'confirm'
    },
    {
        path: '/nda',
        component: () => import("@/views/NDA"),
        meta:{requiresAuth: 'student'},
        name: 'nda'
    },
    // 试卷说明
    {
        path: '/examInstruction',
        component: () => import("@/views/ExamInstruction"),
        name: 'examInstruction',
        meta:{requiresAuth: 'student'}
    },
    // 试卷
    {
        path: '/exam',
        component:ExamPaper,
        name: 'exam',
        // 判断用户是否是从examInstruction考试说明页面过来的
        meta:{requiresFromInstruction:'/examInstruction',requiresAuth: 'student'}

    },
    // 教师路由
    {
        path: '/teacher',
        component: () => import("@/views/TeacherView"),
        meta:{requiresAuth: 'teacher'},
        name: 'teacher'
    },
    // 在登录注册时候显示的保密协议
    {
        path: '/newNDA',
        name: 'newNDA',
        component: () => import("@/views/NewNDA"),
        meta:{requiresAuth: 'any'}
    },
    {
        path: '/newNDA2',
        name: 'newNDA2',
        component: () => import("@/views/NewNDA2"),
        meta:{requiresAuth: 'any'}
    },
    {
        path: '/correct',
        name: 'correct',
        component: () => import("@/views/Correct"),
        meta:{requiresAuth: 'teacher'}
    },
    {
        path: '/ceshi',
        name: 'ceshi',
        component: () => import("@/views/ExamPaper/PaperListen3&4New"),
    },
    {
        path: '/ceshi2',
        name: 'ceshi2',
        component: () => import("@/views/ExamPaper/PaperListen5New"),
    },
    {
        path: '/administrator',
        name: 'administrator',
        component: () => import("@/views/Administrator"),
        meta:{requiresAuth: 'admin'},
        children: [
            {
                path: 'childrenAdmin',
                name: 'childrenAdmin',
                component: () => import("@/views/Administrator/childrenAdmin"),
                meta:{requiresAuth: 'admin'},
            },
            {
                path: 'childrenTeacher',
                name: 'childrenTeacher',
                component: () => import("@/views/Administrator/childrenTeacher"),
                meta:{requiresAuth: 'admin'},
            },
            {
                path: 'childrenStudent',
                name: 'childrenStudent',
                component: () => import("@/views/Administrator/childrenStudent"),
                meta:{requiresAuth: 'admin'},
            },
        ],
        redirect:'/administrator/childrenStudent'
    },

    //重定向，在项目跑起来的时候，访问/，立马定向到首页
    {
        path: '*',
        redirect: '/login',
    }
]