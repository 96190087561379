<template>
  <div class="page_one">
    <div class="level_title self_">
      <span class="B2">Level {{level}}</span>
      <span class="content">
        <span >
          {{ titleList.questionDetail }}
        </span>
      </span>
    </div>
    <div class="contain">
      <!-- 题目 -->
      <div class="title">
        <h3 style="font-weight: bold;text-align: center;font-size: 14px; ">{{ articleTitle }}</h3>
        <br>

        <div class="text">
          <strong v-html="textDetile[0]"></strong>
          (1)<draggable
            v-model="arr2"
            :group="groupB"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr2" :key="item.id">
                {{ item.id }}
                <img src="@/assets/cancel.png" alt="" @click="arr2.pop()" />
              </div>
            </transition-group>
          </draggable>
          <strong v-html="textDetile[1]"></strong>
          (2)<draggable
            v-model="arr3"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr3" :key="item.id">
                {{ item.id }}
                <img src="@/assets/cancel.png" alt="" @click="arr3.pop()" />
              </div>
            </transition-group>
          </draggable>
          <strong v-html="textDetile[2]"></strong>(3)<draggable
            v-model="arr4"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr4" :key="item.id">
                {{ item.id }}
                <img src="@/assets/cancel.png" alt="" @click="arr4.pop()" />
              </div>
            </transition-group>
          </draggable>
          <strong v-html="textDetile[3]"></strong>
          (4)<draggable
            v-model="arr5"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr5" :key="item.id">
                {{ item.id }}
                <img src="@/assets/cancel.png" alt="" @click="arr5.pop()" />
              </div>
            </transition-group>
          </draggable>
          <strong v-html="textDetile[4]"></strong>
          (5)<draggable
            v-model="arr6"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr6" :key="item.id">
                {{ item.id }}
                <img src="@/assets/cancel.png" alt="" @click="arr6.pop()" />
              </div>
            </transition-group>
          </draggable>
          <strong v-html="textDetile[5]"></strong>
          (6)<draggable
            v-model="arr7"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr7" :key="item.id">
                {{ item.id }}
                <img src="@/assets/cancel.png" alt="" @click="arr7.pop()" />
              </div>
            </transition-group>
          </draggable>
          <strong v-html="textDetile[6]"></strong>
        </div>
      </div>
      <!-- 选项 -->
      <div class="option">
        <draggable
          v-model="myArray"
          @start="onStart"
          @end="onEnd"
          animation="300"
          :group="groupA"
        >
          <transition-group>
            <div
              class="item"
              :class="flagList[item.num] == '1' ? 'active' : ''"
              v-for="(item, i) in myArray"
              :key="i"
            >
              <div class="left" style="font-size: 20px">{{ item.id }}</div>
              <div class="right">
                {{ item.name }}
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
export default {
  name: "DargOne",
  data() {
    return {
      // 放置原本答案的数组
      myArray: [
        { id: "A", name: "A",num:0 },
        { id: "B", name: "B",num:1 },
        { id: "C", name: "C",num:2 },
        { id: "D", name: "D",num:3 },
        { id: "E", name: "E",num:4 },
        { id: "F", name: "F",num:5 },
        {id: "G", name: "G",num:6}
      ],
      // 拖拽A组
      groupA: {
        name: "itxst",
        pull: "clone", //可以拖出
        put: false, //可以拖入
      },
      // 拖拽B组
      groupB: {
        name: "site",
        pull: false,
        put: true,
      },
      arr2: [],
      arr3: [],
      arr4: [],
      arr5: [],
      arr6: [],
      arr7:[],
      // 控制样式数组
      flagList: ["", "", "", "", "", "",""],
      titleList: {},
      // 文章信息
      article: "",
      text: "",
      textDetile: [],
      // 文章标题
      articleTitle: "",
      // 试卷答案
      answerList: ["", "", "", "", "",""],
    };
  },
  mounted() {
    // 挂载时获取一次数据
    this.getPaperInfo();
  },
  //注册draggable组件
  components: {
    draggable,
  },
  methods: {
    //开始拖拽事件
    onStart() {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false;
    },
    dropSelect() {
      // console.log(123);
    },
    // 获取试卷信息
    getPaperInfo() {
      setTimeout(() => {
        let result = this.paperData;
        this.titleList = result.readingP2;
        this.articleTitle = this.titleList.article.split("#")[0];
        this.article = this.titleList.article.split("#");
        // 文章换行
        this.article.forEach((item, i) => {
          if (i >= 1) {
            this.text += item + "<br>";
          }
          // console.log(this.text);
          this.textDetile = this.text.split("$");
        });
        this.myArray.forEach((item, i) => {
          item.name = this.titleList.optionList[i];
        });
      }, 1000);
    },
  },
  watch: {
    // 监听答案,下同
    arr2() {
      if (this.arr2.length) {
        this.answerList[0] = this.arr2[0].id;
      } else {
        this.answerList[0] = "";
      }
      if (this.arr2.length >= 2) {
        this.arr2.shift();
      }
    },
    arr3() {
      if (this.arr3.length) {
        this.answerList[1] = this.arr3[0].id;
      } else {
        this.answerList[1] = "";
      }

      if (this.arr3.length >= 2) {
        this.arr3.shift();
      }
    },
    arr4() {
      if (this.arr4.length) {
        this.answerList[2] = this.arr4[0].id;
      } else {
        this.answerList[2] = "";
      }
      if (this.arr4.length >= 2) {
        this.arr4.shift();
      }
    },
    arr5() {
      if (this.arr5.length) {
        this.answerList[3] = this.arr5[0].id || "";
      } else {
        this.answerList[3] = "";
      }

      if (this.arr5.length >= 2) {
        this.arr5.shift();
      }
    },
    arr6() {
      if (this.arr6.length) {
        this.answerList[4] = this.arr6[0].id || "";
      } else {
        this.answerList[4] = "";
      }

      if (this.arr6.length >= 2) {
        this.arr6.shift();
      }
    },
    arr7() {
      if (this.arr7.length) {
        this.answerList[5] = this.arr7[0].id || "";
      } else {
        this.answerList[5] = "";
      }

      if (this.arr7.length >= 2) {
        this.arr7.shift();
      }
    },
    totalArr() {
      // console.log(this.answerList);
      let anwser = []
      this.answerList.forEach((item,i)=>{
        anwser[i] = item
      })
      // console.log(anwser);
      this.$store.dispatch("getAnswerRedTwo", anwser);
      if (this.totalArr.indexOf(this.myArray[0]) == -1) {
        this.flagList[0] = 0;
      } else {
        this.flagList[0] = 1;
      }
      if (this.totalArr.indexOf(this.myArray[1]) == -1) {
        this.flagList[1] = 0;
      } else {
        this.flagList[1] = 1;
      }
      if (this.totalArr.indexOf(this.myArray[2]) == -1) {
        this.flagList[2] = 0;
      } else {
        this.flagList[2] = 1;
      }
      if (this.totalArr.indexOf(this.myArray[3]) == -1) {
        this.flagList[3] = 0;
      } else {
        this.flagList[3] = 1;
      }
      if (this.totalArr.indexOf(this.myArray[4]) == -1) {
        this.flagList[4] = 0;
      } else {
        this.flagList[4] = 1;
      }
      if (this.totalArr.indexOf(this.myArray[5]) == -1) {
        this.flagList[5] = 0;
      } else {
        this.flagList[5] = 1;
      }
      if (this.totalArr.indexOf(this.myArray[6]) == -1) {
        this.flagList[6] = 0;
      } else {
        this.flagList[6] = 1;
      }
    },
  },
  computed: {
    // 将所有数据整合进大数组
    totalArr() {
      return [
        ...this.arr2,
        ...this.arr3,
        ...this.arr4,
        ...this.arr5,
        ...this.arr6,
        ...this.arr7
      ];
    },
    ...mapState({
      paperData: (state) => state.home.data,
      level:(state)=>state.home.level,
    }),
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page_one {
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
}

.self_ {
  margin-bottom: 30px;
}
.level_title {
  width: 100%;
  height: 50px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    height: 50px;
    color: snow;
    text-align: center;
    
  }

  .B2 {
    width: 10%;
    line-height: 50px;
    background-color: rgb(240, 54, 54);
  }

  .topic_B2 {
    background-color: rgb(51, 51, 51);
    font-size: 20px;
  }

  .content {
    padding: 0 30px;
    width: 90%;
    background-color: rgb(51, 51, 51);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: start;
    }
  }
}
.contain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .title {
    width: 55%;
    line-height: 33px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    padding: 20px;
    height: 620px;
    overflow: scroll;
    font-size: 14px;
      line-height: 24px;
    strong {
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;

    }

    h3 {
      font-size: 15px;
      font-weight: normal;
    }
    span {
      display: inline-block;
      height: 28px;
      width: 70px;
      border: 1px solid #000;
      vertical-align: bottom;
      margin: 0 5px;
      overflow: hidden;
      line-height: 24px;
      
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 10px;
        margin-left: 10px;
        
      }
    }
    div {
      display: inline-block;
    }
  }
  .option {
    width: 43%;
    line-height: 33px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    padding: 10px;
    height: 620px;
    overflow: scroll;

    span {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .item {
        width: 100%;
        height: 70px;
        // background-color: #000;
        display: flex;
        font-size: 14px;
        .left {
          width: 70px;
          height: 100%;
          background-color: #000;
          color: #fff;
          line-height: 70px;
          text-align: center;
        }
        .right {
          flex: 1;
          padding: 5px;
          box-sizing: border-box;
          background-color: rgba(166, 166, 166);
          display: flex;
          align-items: center;
          line-height: 20px;
          color: #fff;
        }
      }
      .active {
        box-sizing: border-box;
        .left {
          background-color: rgb(240, 54, 54);
        }
        .right {
          background-color: #fff2f2;
          border: 1px solid rgb(240, 54, 54);
          color: #333;
        }
      }
    }
  }
}

</style>