//当前模块：API接口进行统一管理
import requests from './request';

// 用户注册
export const userRegister = (data) => {
    return requests({
        url: `/user/register`,
        data,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
    })
}
// 用户登录
export const userLogin = (data) => {
    return requests({
        url: `/user/login`, data, method: 'post', headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
    })
}

//获取用户信息
export const getUserInfo = () => {
    return requests({
        url: '/user/getinformation',
        method: 'get'
    })
}

//更新个人信息接口
export const updateUserInfo = (data) => {
    return requests({
        method: 'post',
        url: `/user/updateinformation`,
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 修改密码的接口
export const changePassword = (data) => {
    return requests({
        method: 'post',
        url: ' /user/updatepassword',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 获取试卷信息
export const getPaperInfo = (key) => {
    let data = { key }
    return requests({
        url: `/user/getpaper`, data, method: 'post', headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 用户登出接口
export const signOut = () => {
    return requests({
        url: '/user/logout',
        method: 'get'
    })
}

// 提交试卷接口
export const putPaper = (data) => {
    return requests({
        url: ' /user/checkanswer',
        data,
        method: 'post',
    })
}

// 获取考试历史接口
export const getExamRecord = () => {
    return requests({
        url: ' /user/getexamrecord',
        method: 'get',
    })
}

// 验证key
export const verifyKey = (data) => {
    return requests({
        url: '/user/checkkey',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 教师获取考试记录
//teacher/getexamrecords
export const teacherGetExamRecords = () => {
    return requests({
        url: '/teacher/getexamrecord',
        method: 'get',
    })
}

//教师获取试卷接口
//teacher/getpaperrecord
export const getPaperRecord = (key) => {
    let data = { key }
    return requests({
        url: `/teacher/getpaperrecord`, data, method: 'post', headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 教师提交批改接口
//teacher/correctwriting
export const correct = (data) => {
    return requests({ url: `/teacher/correctwriting`, data, method: 'post' })
}
//教师确认批改按钮
//teacher/confirmcorrect
export const confirmCorrect = (id) => {
    let data = { id }
    return requests({
        url: `/teacher/confirmcorrect`, data, method: 'post', headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 管理员系统   获取用户列表
export const getAdminUsers = (type) => {
    return requests({
        url: 'admin/getuserbytype',
        method: 'post',
        data: type,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 管理员修改密码的接口
export const resetpassword = (data) => {
    return requests({
        url: '/admin/resetpassword',
        data,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 添加教师和管理员接口
export const addAdminOrTeacher = (data) => {
    return requests({
        url: '/admin/adduser',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 通过key搜素用户
export const reqSearchKey = (data) => {
    return requests({
        method: 'post',
        url: '/admin/searchkeylist',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 查看用户key
export const getKeyList = (data) => {
    return requests({
        url: '/admin/getkeylist',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 删除教师用户
export const removeTeacher = (data) => {
    return requests({
        url: '/admin/deleteuserbyid',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

//  获取试卷名接口
export const getPaperName = (data) => {
    return requests({
        method: 'post',
        url: '/admin/getpaperlist',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}

// 生成key的接口
export const birthKey = (data) => {
    return requests({
        method: 'post',
        url: '/admin/getkey',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })
}