<template>
  <div class="page_one">
    <div class="level_title self_">
      <span class="B2">Level {{level}}</span>
      <span class="content">
        <span>
          {{titleList.questionDetail}}
        </span>
      </span>
    </div>
    <div class="contain">
      <div class="left">
        <draggable
          v-model="myArray"
          :group="groupA"
          animation="300"
          dragClass="dragClass"
          ghostClass="ghostClass"
          chosenClass="chosenClass"
          :limit="1"
        >
          <transition-group>
            <div class="item" v-for="(item) in myArray" :key="item.index" :class="flagList[item.index] == '1' ? 'active' : ''">
              <div class="header">{{ item.id }}</div>
              <img :src="!flag?'':item.name" alt="">
              <!-- <div class="body"></div> -->
            </div>
          </transition-group>
        </draggable>
      </div>
      <div class="right">
        <div class="item">
          <div class="title">
            <div class="leftOne">1</div>
            <div class="rightOne">{{titleList.questionList[0]}}</div>
          </div>
          <draggable
            v-model="arr2"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="(item, i) in arr2" :key="i">
                <div class="answer" v-if="item">{{ item.id }}<img src="@/assets/cancel.png" alt="" @click="arr2.pop()" /></div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="item">
          <div class="title">
            <div class="leftOne">2</div>
            <div class="rightOne">{{titleList.questionList[1]}}</div>
          </div>
          <draggable
            v-model="arr3"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="(item, i) in arr3" :key="i">
                <div class="answer" v-if="item">{{ item.id }}<img src="@/assets/cancel.png" alt="" @click="arr3.pop()" /></div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="item">
          <div class="title">
            <div class="leftOne">3</div>
            <div class="rightOne">{{titleList.questionList[2]}}</div>
          </div>
          <draggable
            v-model="arr4"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="(item, i) in arr4" :key="i">
                <div class="answer" v-if="item">{{ item.id }}<img src="@/assets/cancel.png" alt="" @click="arr4.pop()" /></div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="item">
          <div class="title">
            <div class="leftOne">4</div>
            <div class="rightOne">{{titleList.questionList[3]}}</div>
          </div>
          <draggable
            v-model="arr5"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="(item, i) in arr5" :key="i">
                <div class="answer" v-if="item">{{ item.id }}<img src="@/assets/cancel.png" alt="" @click="arr5.pop()" /></div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="item">
          <div class="title">
            <div class="leftOne">5</div>
            <div class="rightOne">{{titleList.questionList[4]}}</div>
          </div>
          <draggable
            v-model="arr6"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="(item, i) in arr6" :key="i">
                <div class="answer" v-if="item">{{ item.id }}<img src="@/assets/cancel.png" alt="" @click="arr6.pop()" /></div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="item">
          <div class="title">
            <div class="leftOne">6</div>
            <div class="rightOne">{{titleList.questionList[5]}}</div>
          </div>
          <draggable
            v-model="arr7"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="(item, i) in arr7" :key="i">
                <div class="answer" v-if="item">{{ item.id }}<img src="@/assets/cancel.png" alt="" @click="arr7.pop()" /></div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="item">
          <div class="title">
            <div class="leftOne">7</div>
            <div class="rightOne">{{titleList.questionList[6]}}</div>
          </div>
          <draggable
            v-model="arr8"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="(item, i) in arr8" :key="i">
                <div class="answer" v-if="item">{{ item.id }}<img src="@/assets/cancel.png" alt="" @click="arr8.pop()" /></div>
              </div>
            </transition-group>
          </draggable>
        </div>
        <div class="item">
          <div class="title">
            <div class="leftOne">8</div>
            <div class="rightOne">{{titleList.questionList[7]}}</div>
          </div>
          <draggable
            v-model="arr9"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="(item, i) in arr9" :key="i">
                <div class="answer" v-if="item">{{ item.id }}<img src="@/assets/cancel.png" alt="" @click="arr9.pop()" /></div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
export default {
  //注册draggable组件
  components: {
    draggable,
  },
  data() {
    return {
      drag: false,
      message: "",
      groupA: {
        name: "itxst",
        put: false, //可以拖入
        pull: "clone",
      },
      groupB: {
        name: "itxst",
        pull: false, //B组拖拽时克隆到A组
        put: true,
      },

      //定义要被拖拽对象的数组
      myArray: [
        {
          id: "A",
          name: "",
          index:0
        },
        {
          id: "B",
          name: "",
          index:1
        },
        {
          id: "C",
          name: "",
          index:2
        },
        {
          id: "D",
          name: "",
          index:3
        },
                {
          id: "E",
          name: "",
          index:4
        },
        {
          id: "F",
          name: "",
          index:5
        },
        {
          id: "G",
          name: "",
          index:6
        },
        {
          id: "H",
          name: "",
          index:7
        },
        {
          id: "I",
          name: "",
          index:8
        }
      ],
      arr2: [],
      arr3: [],
      arr4: [],
      arr5: [],
      arr6: [],
      arr7: [],
      arr8: [],
      arr9: [],
      flagList: ["", "", "", "", "", "", "","",""],
titleList:{
   haveOptionsImg: "",
            haveOptionsText: "",
            haveQuestionText: "",
            id: "",
            optionAText: "",
            optionAUrl: "",
            optionBText: "",
            optionBUrl: "",
            optionCText: "",
            optionCUrl: "",
            optionsNumber: "",
            questionText: "",
            questionList:[]
},
// 收集答案
answerList:["","","","","","","",""],
flag:false
    };
  },
  mounted() {
    this.getPaperInfo()
    setTimeout(()=>{
      this.flag = true
    },5000)
  },
  methods: {
    //开始拖拽事件
    onStart() {
      this.drag = true;
      return true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false;
    },
    // 获取试卷信息
    getPaperInfo() {
      setTimeout(() => {
        let result = this.paperData;
          this.titleList = result.readingP4;
          this.myArray.forEach((item,i)=>{
            item.name = this.titleList.optionList[i].optionUrl
          })  
      }, 1000);
    },
  },
  watch: {
   arr2() {
      if (this.arr2.length) {
        this.answerList[0] = this.arr2[0].id || "";
      } else {
        this.answerList[0] = "";
      }
      if (this.arr2.length >= 2) {
        this.arr2.shift();
      }
    },
    arr3() {
      if (this.arr3.length) {
        this.answerList[1] = this.arr3[0].id || ''; 
        
      }else{
        this.answerList[1] = "";
      }

      if (this.arr3.length >= 2) {
        this.arr3.shift();
      }
    },
    arr4() {
      if (this.arr4.length) {
        this.answerList[2] = this.arr4[0].id || '';
      } else {
        this.answerList[2] = "";
      }
      if (this.arr4.length >= 2) {
        this.arr4.shift();
      }
    },
    arr5() {
      if (this.arr5.length) {
        this.answerList[3] = this.arr5[0].id || "";
      } else {
        this.answerList[3] = "";
      }

      if (this.arr5.length >= 2) {
        this.arr5.shift();
      }
    },
    arr6() {
      if (this.arr6.length) {
        this.answerList[4] = this.arr6[0].id || "";
      } else {
        this.answerList[4] = "";
      }

      if (this.arr6.length >= 2) {
        this.arr6.shift();
      }
    },
    arr7() {
      if (this.arr7.length) {
        this.answerList[5]=this.arr7[0].id || ''
      } else {
        this.answerList[5] = "";
      }
      if (this.arr7.length >= 2) {
        this.arr7.shift();
      }
    },
    arr8() {
      if (this.arr8.length) {
        this.answerList[6]=this.arr8[0].id || ''
      } else {
        this.answerList[6] = "";
      }
      if (this.arr8.length >= 2) {
        this.arr8.shift();
      }
    },
    arr9() {
      if (this.arr9.length) {
        this.answerList[7]=this.arr9[0].id || ''
      } else {
        this.answerList[7] = "";
      }
      if (this.arr9.length >= 2) {
        this.arr9.shift();
      }
    },
    totalArr() {
      let anwser = []
      this.answerList.forEach((item,i)=>{
        anwser[i] = item
      })
      this.$store.dispatch('getAnswerRedFour',anwser)
      if (this.totalArr.some((item)=>item.id == 'A')) {
        this.flagList[0] = 1;
      } else {
        this.flagList[0] = 0;
      }
      if (this.totalArr.some((item)=>item.id == 'B')) {
        this.flagList[1] = 1;
      } else {
        this.flagList[1] = 0;
      }
      if (this.totalArr.some((item)=>item.id == 'C')) {
        this.flagList[2] = 1;
      } else {
        this.flagList[2] = 0;
      }
      if (this.totalArr.some((item)=>item.id == 'D')) {
        this.flagList[3] = 1;
      } else {
        this.flagList[3] = 0;
      }
      if (this.totalArr.some((item)=>item.id == 'E')) {
        this.flagList[4] = 1;
      } else {
        this.flagList[4] = 0;
      }
      if (this.totalArr.some((item)=>item.id == 'F')) {
        this.flagList[5] = 1;
      } else {
        this.flagList[5] = 0;
      }
      if (this.totalArr.some((item)=>item.id == 'G')) {
        this.flagList[6] = 1;
      } else {
        this.flagList[6] = 0;
      }
      if (this.totalArr.some((item)=>item.id == 'H')) {
        this.flagList[7] = 1;
      } else {
        this.flagList[7] = 0;
      }
      if (this.totalArr.some((item)=>item.id == 'I')) {
        this.flagList[8] = 1;
      } else {
        this.flagList[8] = 0;
      }
    },
  },
  computed: {
    totalArr() {
      return [
        ...this.arr2,
        ...this.arr3,
        ...this.arr4,
        ...this.arr5,
        ...this.arr6,
        ...this.arr7,
        ...this.arr8,
        ...this.arr9
      ];
    },
    ...mapState({
      paperData: (state) => state.home.data,
      level:(state)=>state.home.level,
    }),
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;

}

.page_one {
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
}

.self_ {
  margin-bottom: 30px;
}
.level_title {
  width: 100%;
  height: 50px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    height: 50px;
    color: snow;
    text-align: center;
  }

  .B2 {
    width: 10%;
    line-height: 50px;
    background-color: rgb(240, 54, 54);
    
  }

  .topic_B2 {
    background-color: rgb(51, 51, 51);
    font-size: 20px;
  }

  .content {
    padding: 0 30px;
    width: 90%;
    background-color: rgb(51, 51, 51);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: start;
    }
  }
  .topic_con {
    background-color: rgb(166, 166, 166);
  }
}
.contain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .left {
    width: 55%;
    height: 600px;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    overflow: scroll;
    
    div{
      width: 100%;
      
    }
    span{
      width: 100%;
      display: flex;
    flex-wrap: wrap;
    gap: 10px;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 48%;
      margin: 0;
      .header {
        height: 50px;
        width: 100%;
        background-color: #333;
        color: #fff;
        line-height: 50px;
        font-weight: 900;
        font-size: 24px;
        text-align: center;
      }
      img{

        width: 200px;
        animation: leftImg 0.5s;
        }
      .body {
        width: 220px;

        padding: 10px;
        
      }
    }
    .active {
        box-sizing: border-box;
        .header {
          background-color: rgb(240, 54, 54);
        }
      }
  }
  .right {
    width: 43%;
    height: 600px;
    box-sizing: border-box;
    padding: 10px;
    overflow: scroll;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    span {
      display: inline-block;
      height: 33px;
      width: 98%;
      border: 1px solid #000;
      vertical-align: bottom;
      margin: 0 5px;
      overflow: hidden;
      text-align: center;
      line-height: 33px;
      position: relative;
      box-sizing: border-box;
      img{
        width: 12px;
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
    .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;
      .title {
        height: 50px;
        width: 100%;

        display: flex;

        .leftOne {
          width: 60px;
          height: 50px;
          background-color: #333;
          color: #fff;
          text-align: center;
          line-height: 50px;
          font-size: 20px;
        }
        .rightOne {
          flex: 1;
          height: 50px;
          background-color: #a6a6a6;
          color: #fff;
          line-height: 50px;
          padding-left: 5px;
          font-size: 14px;
        }
      }
    }
  }
}
@keyframes leftImg {
  from {
    opacity: 0;
    transform: scale(0.98);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>