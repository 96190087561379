import Vue from 'vue'
import App from './App.vue'

//引入路由
import router from '@/router'
//引入仓库
import store from './store';
//统一接口api文件夹里面全部请求函数
import * as API from '@/api'
// 引入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI)
// 隐藏 右侧浏览器 滚动条
// import VueSmoothScroll from "vue2-smooth-scroll";
// Vue.use(VueSmoothScroll)
// 周工qq:1594428906
import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards);

Vue.config.productionTip = false
// 懒加载
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require('./assets/error.png'),
  loading: require('./assets/loading.png'),
  attempt: 2,
});
// 注册全局组件
import Category from './component/category'
Vue.component(Category.name,Category)

new Vue({
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this;
    Vue.prototype.$API = API;
  },
  router,
  //组件实例身上会多一个属性$store属性
  store,
}).$mount('#app')
