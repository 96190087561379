<template>
  <div class="page_one">
    <div class="level_title self_">
      <span class="B2">Level A1</span>
      <span class="content">
        <span>
          You will hear some short conversations. You will hear each
          conversation twice. Choose the correct answer to complete each
          conversation
        </span>
      </span>
    </div>
    <div class="contain">
      <!-- 题目 -->
      <div class="title">
        <h3>Why we walk</h3>
        <div class="text">
          In many remote areas of the planet, walking is the only way to get
          anywhere. However, there are towns, especially in the USA, where
          people almost never walk, but either drive everywhere by car or use
          other means of transport. (1)<draggable
            v-model="arr2"
            :group="groupB"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr2" :key="item.id">
                {{ item.name }}
                <img src="@/assets/cancel.png" alt="" @click="arr2.pop()" />
              </div>
            </transition-group>
          </draggable>
          We therefore asked some of them why they act differently from their
          neighbours and here are their answers:
          <br />
          Contrary to what most of my neighbours do, most days of the week, the
          only “transportation” I use is my feet. The grocery store and the yoga
          studio I go to regularly are near my home. (2)<draggable
            v-model="arr3"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr3" :key="item.id">
                {{ item.name }}
                <img src="@/assets/cancel.png" alt="" @click="arr3.pop()" />
              </div>
            </transition-group>
          </draggable>
          If I need a longer break, the beach is only about a mile from my
          house’, says Margaret.
          <br />
          Katherine explains why people may use their cars so much. ‘In the
          small town where I live’, she says, ‘traffic jams don't exist, except
          at rush hour when people coming home from work just wait at the
          traffic lights for a few minutes longer than usual. Therefore, most
          inhabitants find it convenient to use their cars, even if they’re just
          going round the corner to buy bread! (3)<draggable
            v-model="arr4"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr4" :key="item.id">
                {{ item.name }}
                <img src="@/assets/cancel.png" alt="" @click="arr4.pop()" />
              </div>
            </transition-group>
          </draggable>
          Nevertheless, I prefer to walk because it’s good for the environment
          and because it promotes a healthier way of life for me and my
          children.
          <br />
          Katherine mentions another advantage of walking, which is not usually
          considered. She says: (4)<draggable
            v-model="arr5"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr5" :key="item.id">
                {{ item.name }}
                <img src="@/assets/cancel.png" alt="" @click="arr5.pop()" />
              </div>
            </transition-group>
          </draggable>
          They pay attention to their surroundings and are less likely to get
          lost. I like to allow my kids to take the lead and show me how to get
          to places.’ Walking is also, perhaps surprisingly, safer for children.
          (5)<draggable
            v-model="arr6"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr6" :key="item.id">
                {{ item.name }}
                <img src="@/assets/cancel.png" alt="" @click="arr6.pop()" />
              </div>
            </transition-group>
          </draggable>
          Car crashes are a serious problem in the United States and walking
          clearly helps people avoid them. On the whole, it seems there are many
          reasons to consider walking to your destination rather than taking the
          car, but the most important are those mentioned above. (6)<draggable
            v-model="arr7"
            :group="groupB"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @start="onStart"
            @end="onEnd"
          >
            <transition-group>
              <div class="item" v-for="item in arr7" :key="item.id">
                {{ item.name }}
                <img src="@/assets/cancel.png" alt="" @click="arr7.pop()" />
              </div>
            </transition-group>
          </draggable>
          The benefits are endless if we decide to make the change.
        </div>
      </div>

      <!-- 选项 -->
      <div class="option">
        <draggable
          v-model="myArray"
          @start="onStart"
          @end="onEnd"
          animation="300"
          :group="groupA"
        >
          <transition-group>
            <div
              class="item"
              :class="flagList[i] == '1' ? 'active' : ''"
              v-for="(item, i) in myArray"
              :key="i"
            >
              <div class="left">{{ item.name }}</div>
              <div class="right">
                In other words, it keeps you safer and healthier, allows you to
                know the area where you live and reduces pollution.
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "PaperThree",
  data() {
    return {
      myArray: [
        { id: 10, name: "A" },
        { id: 20, name: "B" },
        { id: 30, name: "C" },
        { id: 40, name: "D" },
      ],
      groupA: {
        name: "itxst",
        pull: "clone", //可以拖出
        put: false, //可以拖入
      },
      groupB: {
        name: "site",
        pull: false,
        put: true,
      },
      arr2: [],
      arr3: [],
      arr4: [],
      arr5: [],
      arr6: [],
      arr7: [],
      flagList: ["", "", "", "", "", "", ""],
    };
  },
  //注册draggable组件
  components: {
    draggable,
  },
  methods: {
    //开始拖拽事件
    onStart() {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false;
    },
    dropSelect() {
      console.log(123);
    },
  },
  watch: {
    arr2() {
      if (this.arr2.length >= 2) {
        this.arr2.shift();
      }
    },
    arr3() {
      if (this.arr3.length >= 2) {
        this.arr3.shift();
      }
    },
    arr4() {
      if (this.arr4.length >= 2) {
        this.arr4.shift();
      }
    },
    arr5() {
      if (this.arr5.length >= 2) {
        this.arr5.shift();
      }
    },
    arr6() {
      if (this.arr6.length >= 2) {
        this.arr6.shift();
      }
    },
    arr7() {
      if (this.arr7.length >= 2) {
        this.arr7.shift();
      }
    },
    totalArr() {
      if (this.totalArr.indexOf(this.myArray[0]) == -1) {
        this.flagList[0] = 0;
      } else {
        this.flagList[0] = 1;
      }
      if (this.totalArr.indexOf(this.myArray[1]) == -1) {
        this.flagList[1] = 0;
      } else {
        this.flagList[1] = 1;
      }
      if (this.totalArr.indexOf(this.myArray[2]) == -1) {
        this.flagList[2] = 0;
      } else {
        this.flagList[2] = 1;
      }
      if (this.totalArr.indexOf(this.myArray[3]) == -1) {
        this.flagList[3] = 0;
      } else {
        this.flagList[3] = 1;
      }
    },
  },
  computed: {
    totalArr() {
      return [
        ...this.arr2,
        ...this.arr3,
        ...this.arr4,
        ...this.arr5,
        ...this.arr6,
        ...this.arr7,
      ];
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page_one {
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
}

.self_ {
  margin-bottom: 30px;
}
.level_title {
  width: 100%;
  height: 50px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    height: 50px;
    color: snow;
    text-align: center;
  }

  .B2 {
    width: 10%;
    line-height: 50px;
    background-color: rgb(240, 54, 54);
  }

  .topic_B2 {
    background-color: rgb(51, 51, 51);
    font-size: 20px;
  }

  .content {
    padding: 0 60px;
    width: 90%;
    background-color: rgb(51, 51, 51);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: start;
    }
  }
}
.contain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .title {
    width: 52%;
    line-height: 33px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    padding: 20px;
    height: 620px;
    overflow: scroll;
    .text {
      text-align: justify;
    }
    h3 {
      font-size: 15px;
      margin-left: 20px;
    }
    span {
      display: inline-block;
      height: 33px;
      width: 80px;
      border: 1px solid #000;
      vertical-align: bottom;
      margin: 0 5px;
      overflow: hidden;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 10px;
        margin-left: 10px;
      }
    }
    div {
      display: inline-block;
    }
  }
  .option {
    width: 45%;
    line-height: 33px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    padding: 20px;
    height: 620px;
    overflow: scroll;

    span {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .item {
        width: 100%;
        height: 70px;
        // background-color: #000;
        display: flex;
        .left {
          width: 70px;
          height: 100%;
          background-color: #000;
          color: #fff;
          line-height: 70px;
          text-align: center;
        }
        .right {
          flex: 1;
          padding: 5px;
          box-sizing: border-box;
          background-color: rgba(166, 166, 166);
          display: flex;
          align-items: center;
          line-height: 20px;
          font-size: 13px;
          color: #fff;
        }
      }
      .active {
        box-sizing: border-box;
        .left {
          background-color: rgb(240, 54, 54);
        }
        .right {
          background-color: #fff2f2;
          border: 1px solid rgb(240, 54, 54);
          color: #333;
        }
      }
    }
  }
}
</style>