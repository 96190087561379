<template>
    <div>
        <el-card>
            <el-form inline :model="attrForm" style="margin-top: 20px;">
                <!-- 不在一行 ，使用 :inline属性变行内 -->
                <el-form-item label="一级分类" inline>
                    <el-select v-model="attrForm.c1Id" clearable @change="c1Handel">
                        <el-option :label="c1" :value="c1" v-for="(c1, index) in this.C1Arr" :key="c1.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="二级分类">
                    <el-select v-model="attrForm.c2Id" clearable>
                        <el-option :label="c2.name" :value="c2.id" v-for="(c2, index) in this.C2Arr"
                            :key="c2.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-button type="primary" style="margin-left:20px" @click="c2Handel">确认添加Code</el-button>
            </el-form>

            <!-- 查看key弹出的对话框 -->
            <el-dialog title="查看用户Code" :visible.sync="lookKeyVisible">
                <el-table :data="keyData">
                    <el-table-column type="index" label="序号" width="60" align="center">
                    </el-table-column>
                    <!-- code -->
                    <el-table-column prop="paperKey" label="Code" width="160" align="center">
                    </el-table-column>
                    <!-- 对应试卷难度 -->
                    <el-table-column prop="paper.examClass" label="考试等级" width="80" align="center">
                    </el-table-column>
                    <!-- 对应的试卷 -->
                    <el-table-column prop="paper.name" label="试卷名称" width="160" align="center">
                    </el-table-column>
                </el-table>
            </el-dialog>
        </el-card>
    </div>
</template>
  
<script>
export default {
    name: "Category",
    props: ['fetchCategory', 'userId'],
    data() {
        return {
            city: "",
            //  一级分类的数据
            C1Arr: ["A1", 'A2', 'B1', 'B2', 'C1'],
            // 二级分类的数据
            C2Arr: [],
            // 三级分类的数据
            C3Arr: [],
            // form表单收集的数据
            attrForm: {
                // 里面select收集到的数据 c1
                c1Id: '',
                // c2 的id
                c2Id: '',
                // c3的id
                c3Id: ''
            },
            newCode: '',  //存储生成新的Code值的地方
            // 查看key的对话框
            lookKeyVisible: false,
            keyData: [
            ],
        };
    },
    components: {

    },
    mounted() {

    },
    methods: {

        // 二级分类的展示(触发在一级分类select的值改变就发请求展示二级分类)
        async c1Handel() {
            // 向父亲发c1Id ,传递第二个参数 是可以判断是第几个Id
            //   this.fetchCategory(this.attrForm.c1Id,1)
            // 整理参数 c1的id
            const examClass = this.attrForm.c1Id
            //   console.log(c1Id,222,this.userId)
            const userId = this.userId
            // 清空后面的两个参数 c3Id,c2Id
            this.attrForm.c2Id = ''
            this.attrForm.c3Id = ''
            //   console.log({userId,examClass})
            // 发请求
            try {
                let result = await this.$API.getPaperName({ userId, examClass })
                if (result.status = 'success') {
                    this.C2Arr = result.data
                }
                console.log(result)

            } catch (error) {

            }
        },

        // 三级分类展示（发请求实在二级分类改变的时候）
        async c2Handel() {
            // 向父亲发送二级分类的id，和level 2
            // this.fetchCategory(this.attrForm.c2Id, 2)
            const paperId = this.attrForm.c2Id
            const userId = this.userId
            // 二级分类改变要清空三级分类
            //   this.attrForm.c3Id = ''
            if (this.attrForm.c2Id && this.attrForm.c1Id)
                try {
                    let result = await this.$API.birthKey({ paperId, userId })
                    // this.newCode = result.data
                    console.log(result)
                    if (result.status == 'success') {
                        // this.$alert(`Code: ${result.data}`, {
                        //     dangerouslyUseHTMLString: true
                        // })
                        this.lookKeyVisible = true
                        this.C2Arr = ''
                        this.attrForm.c2Id = ''
                        this.attrForm.c1Id = ''

                    }
                } catch (error) {

                }

        },


    },
};
</script>
  
<style></style>