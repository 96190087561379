<template>
    <div class="swiper">
        <!-- v-if="level == 'E1'" -->
        <div class="page_one">
            <div class="level_title self_">
                <span class="B2">A2 Key</span>
                <span class="content">
                    <span>
                        {{ listeningP3.questionDetail }}
                    </span>
                </span>
            </div>
            <div class="level_title self_">
                <span class="B2">Part 3</span>
                <span class="content">
                    <span>
                        You will hear Jasmin talking to her friend Alan about camping. 
                    </span>
                </span>
            </div>
            <!-- part3的听力题目 -->
            <div v-for="(item, index) in listeningP3.questionList" :key="item.id">
                <div class="level_title topic">
                    <span class="B2 topic_B2">{{ index + 11 }}</span>
                    <span class="content topic_con">{{ item.questionText }}</span>
                </div>
                <div class="anwser">
                    <li class="level_title anwser_tit" @click="handleAnswerSelect(item, index, 'A')"
                        :class="{ selected_: answers[index] == 'A' }">
                        <span class="B2 anwsers_" :class="{ spa: answers[index] == 'A' }">A</span>
                        <span class="anwsers_con">{{ item.optionAText }}</span>
                    </li>

                    <li class="level_title anwser_tit" @click="handleAnswerSelect(item, index, 'B')"
                        :class="{ selected_: answers[index] == 'B' }">
                        <span class="B2 anwsers_" :class="{ spa: answers[index] == 'B' }">B</span>
                        <span class="anwsers_con">{{ item.optionBText }}</span>
                    </li>
                    <li class="level_title anwser_tit" @click="handleAnswerSelect(item, index, 'C')"
                        :class="{ selected_: answers[index] == 'C' }">
                        <span class="B2 anwsers_" :class="{ spa: answers[index] == 'C' }">C</span>
                        <span class="anwsers_con">{{ item.optionCText }}</span>
                    </li>
                </div>
            </div>
            <!-- 第四个part的听力题目 -->
            <div class="level_title self_">
                <span class="B2">Part 4</span>
                <span class="content">
                    <span>
                        For each question, choose the correct answer.
                    </span>
                </span>
            </div>
            <div v-for="(item, index) in listeningP4.questionList" :key="item.id">
                <div class="level_title topic">
                    <span class="B2 topic_B2">{{ index + 16 }}</span>
                    <span class="content topic_con">{{ item.questionText }}</span>
                </div>
                <div class="anwser">
                    <li class="level_title anwser_tit" @click="handleAnswerSelect(item, index+5, 'A')"
                        :class="{ selected_: answers[index+5] == 'A' }">
                        <span class="B2 anwsers_" :class="{ spa: answers[index+5] == 'A' }">A</span>
                        <span class="anwsers_con">{{ item.optionAText }}</span>
                    </li>

                    <li class="level_title anwser_tit" @click="handleAnswerSelect(item, index+5, 'B')"
                        :class="{ selected_: answers[index+5] == 'B' }">
                        <span class="B2 anwsers_" :class="{ spa: answers[index+5] == 'B' }">B</span>
                        <span class="anwsers_con">{{ item.optionBText }}</span>
                    </li>
                    <li class="level_title anwser_tit" @click="handleAnswerSelect(item, index+5, 'C')"
                        :class="{ selected_: answers[index+5] == 'C' }">
                        <span class="B2 anwsers_" :class="{ spa: answers[index+5] == 'C' }">C</span>
                        <span class="anwsers_con">{{ item.optionCText }}</span>
                    </li>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "PaperListen3And4New",
    data() {
        return {
            Level: "C", // 用来判断
            listeningP3: {
                haveTitle: "",
                id: "34",
                questionDetail: "",
                questionList: [],
                questionNumber: "",
                title: null,
            },
            listeningP4: {
                haveTitle: "",
                id: "",
                questionDetail: "",
                questionList: [],

                questionNumber: "",
                title: null,
            },
            answers: {}, // 用于存储用户的答案

            // 填空题类型收集的答案
            listeningP3Blank: [],
            answerList: [],
        };
    },
    beforeUpdated() {
        this.getPaperInfo();
    },
    mounted() {
        this.getPaperInfo();
    },
    methods: {
        getPaperInfo() {
            let result = this.paperData.listeningP3 || {
                haveTitle: "",
                id: "",
                questionDetail: "",
                questionList: [{ id: 397, haveQuestionText: false, questionText: null, optionAText: "Of course. Sorry, I forgot.", optionBText: "Well that's a big problem.", optionCText: "Oh, sorry. I'll finish it soon." },],
                questionNumber: "",
                title: null,
            }
            this.listeningP3 = {
                ...result,
                questionList: result.questionList.slice(0, 5)
            };

            this.listeningP4 = {
                ...result,
                questionList: result.questionList.slice(5)
            };

            this.listeningP3.questionList.forEach((item, i) => {
                // this.listeningP3Blank[i] = ""; // 更新 this.listeningP3Blank
                this.answerList[i] = "";       // 更新 this.answerList
            });

            // 处理第二个部分（this.listeningP4）

            this.listeningP4.questionList.forEach((item, i) => {
                // this.listeningP3Blank[i + 5] = ""; // 更新 this.listeningP3Blank 从第六个位置开始
                this.answerList[i + 5] = "";      // 更新 this.answerList 从第六个位置开始
            });
        },

        handleAnswerSelect(item, index, answer) {
            // 在answers对象中存储该题目的答案选项
            // 直接在数组里面添加数据不是响应式的，通过$set设置的数据才是响应式数据
            this.$set(this.answers, index, answer);
        },
        submit() {
            // console.log(this.answers);
        },
    },

    computed: {
        ...mapState({
            paperData: (state) => state.home.data,
            level: (state) => state.home.level,
        }),
    },
    watch: {
        // 收集答案
        answers: {
            handler(newVal, oldVal) {
                // let answerList = [];

                // console.log(this.answers);
                for (let info in newVal) {
                    if (newVal[info]) {
                        // console.log(newVal[info]);
                        this.answerList[info] =
                            newVal[info];
                    } else {
                        this.answerList[info] = "";
                    }
                }
                //  console.log(answerList);
                let anwser = [...this.answerList];
                this.$store.dispatch("getAnswerLisThree", anwser);
                console.log(anwser)
            },
            deep: true,
        },
        //
        listeningP3Blank: {
            handler(newVal, oldVal) {

                setTimeout(() => {
                    let anwser = [];
                    this.listeningP3.questionList.forEach((item, i) => {
                        anwser[i] = "";
                    });
                    // console.log(this.listeningP3Blank);
                    this.listeningP3Blank.forEach((item, i) => {
                        // console.log(item);
                        if (item != "") {
                            anwser[i] = item;
                        }
                    });
                    this.$store.dispatch("getAnswerLisThree", anwser);
                }, 100)


            },
            deep: true,
            immediate: true
        },
    },
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
    text-decoration: none;
}

.page_one {
    width: 100%;
    margin: 0 auto;

    .self_ {
        margin-bottom: 30px;
    }

    .level_title {
        width: 100%;
        height: 50px;
        border: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .level_title span {
        height: 50px;
        color: snow;
        text-align: center;
    }

    .level_title .B2 {
        box-sizing: border-box;
        width: 10%;
        line-height: 50px;
        background-color: #f03636;
    }

    .level_title .topic_B2 {
        font-size: 20px;

        box-sizing: border-box;
        background-color: #333333;
    }

    .level_title .content {
        box-sizing: border-box;
        padding: 0 30px;
        width: 90%;
        background-color: #333333;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .level_title .content span {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-align: start;
    }

    .level_title .topic_con {
        background-color: #a6a6a6;
        font-size: 14px;
    }


    div {
        margin-top: 25px;
    }

    div .anwser {
        padding-left: 5%;
        margin-top: 25px;
    }

    div .anwser .anwser_tit {
        height: 35px;
        margin-top: 10px;
    }

    div .anwser .anwser_tit .anwsers_ {
        //   width: 45px;
        width: 5.4%;
        height: 35px;
        line-height: 35px;
        background-color: #151414;
        border: none;
        font-size: 20px;
    }

    div .anwser .anwser_tit .anwsers_con {
        height: 35px;
        line-height: 35px;
        color: black;
        // background-color: #fffefe;
        margin-left: 30px;
    }

    .selected_ {
        border: 1px solid red;
    }

    .spa {
        display: block;
        background: #f03636 !important;
    }
}

.blank_ {
    width: 100%;
    margin: 0 auto;

    .self_ {
        margin-bottom: 30px;
        margin-top: 25px;
    }

    .level_title {
        width: 100%;
        height: 50px;
        border: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .level_title span {
        height: 50px;
        color: snow;
        text-align: center;
    }

    .level_title .B2 {
        width: 10%;
        line-height: 50px;
        background-color: #f03636;
    }

    .level_title .content {
        padding: 0 30px;
        box-sizing: border-box;
        width: 90%;
        background-color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .level_title .content span {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-align: start;
    }

    .information {
        width: 100%;
        margin: 20px auto;
        font-weight: bold;
        // border: 1px solid rgb(212, 212, 217);
        border: none;

        .content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgb(252, 252, 255);
            letter-spacing: 2px;

            span {
                font-size: 24px;
                color: black;
            }
        }
    }

    .topic {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: none;
        margin-bottom: 25px;
    }

    .topic .question {
        width: 45%;
        background-color: #333333;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: start;
        border: 1px solid gray;
        box-sizing: border-box;
    }

    .topic .question .question_sort {
        width: 25.6%;
        font-size: 20px;
        background-color: #333333;
    }

    .topic .question .question_tit {
        font-size: 14px;
        width: 80%;
        display: flex;
        align-items: center;
        // justify-content: center;
        padding-left: 30px;
        background-color: #a6a6a6;
    }

    .topic .anwser {
        width: 45%;
        height: 100%;
        background-color: #333333;
    }

    .topic .anwser .anwser_blank {
        width: 100%;
        height: 100%;
    }

    .topic .anwser .anwser_blank input {
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 14px;

        display: inline-block;
        width: 100%;
        height: 100%;
    }
}
</style>