import {getPaperInfo} from '@/api'
//state：存储数据的地方
const state = {
data:JSON.parse(sessionStorage.getItem('data')),
level:JSON.parse(sessionStorage.getItem('level')),
key:JSON.parse(sessionStorage.getItem('code')),
listeningResult:[],
readingResult:[],
writingPoint1:'',
writingPoint2:''
};
//mutations：修改state的唯一手段
const mutations = {
    TEACHER(state,{data,level,key}){
        state.data = data
        state.level = level
        state.key = key
    },
    LISTENING(state,listeningResult){
        state.listeningResult = listeningResult
    },
    READ(state,readingResult){
        state.readingResult = readingResult
    },
    WRITEONE(state,writingPoint1){
        state.writingPoint1 = writingPoint1
    },
    WRITETWO(state,writingPoint2){
        state.writingPoint2 = writingPoint2
    }
}
//actions：处理actions，可以书写自己的业务逻辑，也可以处理异步
const actions = {
    teacherGetPaper({commit},{data,level,key}){
        // console.log(result);
        commit('TEACHER',{data,level,key})
    },
    lisAnswer({commit},listeningResult){
        commit('LISTENING',listeningResult)
    },
    readAnswer({commit},readingResult){
        commit('READ',readingResult)

    },
    writerOne({commit},writingPoint1){
        commit('WRITEONE',writingPoint1)
    },
    writerTwo({commit},writingPoint2){
        commit('WRITETWO',writingPoint2)
    }
}
//getters：计算属性，用于简化仓库数组，让组件获取仓库数组更加方便
const getters = {}
export default {
    state,
    mutations,
    actions,
    getters
}