<template>
  <div class="page_one">
            <div class="level_title self_">
                <span class="B2">Level A1</span>
                <span class="content">
                    <span>
                        You will hear some short conversations. You will hear each conversation twice. Choose the
                        correct answer to complete each conversation
                    </span>
                </span>
            </div>
            <!-- 第一题 -->
            <div class="topic  level_title">
                <div class=" question ">
                    <span class="B2 question_sort">1</span>
                    <span class="question_tit">Public-speaking competition Date of</span>
                </div>
                <div class="anwser">
                    <li class="anwser_blank">
                        <input type="text" placeholder="Please enter the">
                    </li>

                </div>
            </div>
            <!-- 第一题 -->
            <div class="topic  level_title">
                <div class=" question ">
                    <span class="B2 question_sort">2</span>
                    <span class="question_tit">Public-speaking competition Date of</span>
                </div>
                <div class="anwser">
                    <li class="anwser_blank">
                        <input type="text" placeholder="Please enter the">
                    </li>

                </div>
            </div>
            <!-- 第一题 -->
            <div class="topic  level_title">
                <div class=" question ">
                    <span class="B2 question_sort">3</span>
                    <span class="question_tit">Public-speaking competition Date of</span>
                </div>
                <div class="anwser">
                    <li class="anwser_blank">
                        <input type="text" placeholder="Please enter the">
                    </li>

                </div>
            </div>

            <!-- 第一题 -->
            <div class="topic  level_title">
                <div class=" question ">
                    <span class="B2 question_sort">4</span>
                    <span class="question_tit">Public-speaking competition Date of</span>
                </div>
                <div class="anwser">
                    <li class="anwser_blank">
                        <input type="text" placeholder="Please enter the">
                    </li>

                </div>
            </div>
            <!-- 第一题 -->
            <div class="topic  level_title">
                <div class=" question ">
                    <span class="B2 question_sort">5</span>
                    <span class="question_tit">Public-speaking competition Date of</span>
                </div>
                <div class="anwser">
                    <li class="anwser_blank">
                        <input type="text" placeholder="Please enter the">
                    </li>

                </div>
            </div>
            <!-- 第一题 -->
            <div class="topic  level_title">
                <div class=" question ">
                    <span class="B2 question_sort">6</span>
                    <span class="question_tit">Public-speaking competition Date of</span>
                </div>
                <div class="anwser">
                    <li class="anwser_blank">
                        <input type="text" placeholder="Please enter the">
                    </li>

                </div>
            </div>

        </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
    text-decoration: none;
}


.page_one {
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
}

.self_ {
    margin-bottom: 30px;
    
}

.level_title {
    width: 100%;
    height: 50px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: start;

    span {
        height: 50px;
        color: snow;
        text-align: center;
    }

    .B2 {
        width:10%;
        line-height: 50px;
        background-color: rgb(240, 54, 54);
        font-size: 20px;
    }


    .content {
        padding: 0 60px;
        width: 90%;
        background-color: rgb(51, 51, 51);
        display: flex;
        align-items: center;
        justify-content: center;
box-sizing: border-box;
        span {
            display: flex;
            align-items: center;
            font-size: 14px;
            text-align: start;

        }
    }
}

.topic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    margin-bottom: 25px;
    
    .question {
        width: 45%;
        background-color: rgb(51, 51, 51);
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: start;

        .question_sort {
            width:20%;

            font-size: 18px;
            background-color: rgb(51, 51, 51);

        }

        .question_tit {
            font-size: 14px;
            width:80%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(166, 166, 166);
        }
    }

    // .anwser {
    //     width: 45%;
    //     height: 100%;
    //     background-color: rgb(51, 51, 51);

    //     .anwser_blank {
    //         width: 100%;
    //         height: 100%;

    //         input {
    //             padding-left: 20px;
    //             box-sizing: border-box;
    //             font-size: 18px;
    //             display: inline-block;
    //             width: 100%;
    //             height: 100%;
    //         }
    //     }
    // }
}

.L4_two{
    .level_title {
    width: 100%;
    height: 50px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: start;

    span {
        height: 50px;
        color: snow;
        text-align: center;
    }

    .B2 {
        width:10%;
        line-height: 50px;
        background-color: rgb(240, 54, 54);
    }

    .anwser{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        .anwser_L4{
            display: inline-block;
            width: 30px;

            input{
                // display: block;
                width: 30px;
        }
        }
        
    }

   
}

}
</style>