<template>
  <div class="swiper">
    <div class="page_one">
      <div class="level_title self_">
        <span class="B2">Level {{level}}</span>
        <span class="content">
          <span>
            {{ listeningP2.questionDetail }}
          </span>
        </span>
      </div>
      <!-- 第一题 -->
      <div v-for="(item, index) in listeningP2.questionList" :key="item.id">
        <div class="level_title topic">
          <span class="B2 topic_B2">{{ index + 1 }}</span>
          <span class="content topic_con">{{ item.questionText }}</span>
        </div>
        <div class="anwser">
          <li
            class="level_title anwser_tit"
            @click="handleAnswerSelect(item,index, 'A')"
            :class="{ selected_: answers[index] == 'A' }"
          >
            <span class="B2 anwsers_" :class="{ spa: answers[index] == 'A' }"
              >A</span
            >
            <span class="anwsers_con">{{ item.optionAText }}</span>
          </li>

          <li
            class="level_title anwser_tit"
            @click="handleAnswerSelect(item,index, 'B')"
            :class="{ selected_: answers[index] == 'B' }"
          >
            <span class="B2 anwsers_" :class="{ spa: answers[index] == 'B' }"
              >B</span
            >
            <span class="anwsers_con">{{ item.optionBText }}</span>
          </li>
          <li
            class="level_title anwser_tit"
            @click="handleAnswerSelect(item,index, 'C')"
            :class="{ selected_: answers[index] == 'C' }"
          >
            <span class="B2 anwsers_" :class="{ spa: answers[index] == 'C' }"
              >C</span
            >
            <span class="anwsers_con">{{ item.optionCText }}</span>
          </li>
        </div>
      </div>
      <!-- <button class="finish_btn" @click="submit">Finish</button> -->
    </div>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";

export default {
  name: "PaperListen2",
  data() {
    return {
      listeningP2: {
        haveTitle: "",
        id: 2,
        questionDetail: "",
        questionList: [],
        questionNumber: 7,
        title: null,
      },

      currentTopic: 1, // 设置当前题目为 id 为 1 的题目
      answers: {}, // 用于存储用户的答案
    };
  },
  mounted() {
    this.getPaperInfo();
  },
  methods: {
    getPaperInfo() {
      setTimeout(() => {
        let result = this.paperData.listeningP2;

        this.listeningP2 = result || [];
      }, 1000);
    },

    handleAnswerSelect(item, index,answer) {
      // 在answers对象中存储该题目的答案选项
      // this.answers[item.id] = answer;
      // 直接在数组里面添加数据不是响应式的，通过$set设置的数据才是响应式数据
      this.$set(this.answers, index, answer);
    },
    // submit() {
    //   console.log(this.answers);
    // },
  },

  computed: {
    ...mapState({
      paperData: (state) => state.home.data,
      level:(state)=>state.home.level,
    }),
  },
  watch:{
    // 收集答案
    answers:{
      
      handler(newVal, oldVal) {
        let answerList = []
        this.listeningP2.questionList.forEach((item,i)=>{
          answerList[i] = ''
        })
        // console.log(this.answers);
            for(let info in newVal){
              if(newVal[info]){
                // console.log(newVal[info]);
                answerList[info] = newVal[info]
              }else{
                answerList[info] = ''
              }
            }
            console.log(answerList);
            this.$store.dispatch('getAnswerLisTwo',answerList)
        },
        deep: true

    }
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.page_one {
  width: 100%;
  margin: 0 auto;
}
.self_ {
  margin-bottom: 30px;
}
.level_title {
  width: 100%;
  height: 50px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: start;
}
.level_title span {
  height: 50px;
  color: snow;
  text-align: center;
}
.level_title .B2 {
  box-sizing: border-box;
 
  width: 10%;
  line-height: 50px;
  background-color: #f03636;
}
.level_title .topic_B2 {
  font-size: 20px;

  box-sizing: border-box;
  background-color: #333333;
}
.level_title .content {
  box-sizing: border-box;
  padding: 0 30px;
  width: 90%;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: start;
}
.level_title .content span {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: start;
}
.level_title .topic_con {
  background-color: #a6a6a6;
  font-size: 14px;
}
div {
  margin-top: 25px;
}
div .anwser {
  padding-left: 5%;
  margin-top: 25px;
}
div .anwser .anwser_tit {
  height: 35px;
  margin-top: 10px;
}
div .anwser .anwser_tit .anwsers_ {
  //   width: 45px;
  width: 5.4%;
  height: 35px;
  line-height: 35px;
  background-color: #151414;
  border: none;
  font-size: 20px;
}
div .anwser .anwser_tit .anwsers_con {
  height: 35px;
  line-height: 35px;
  color: black;
  // background-color: #fffefe;
  margin-left: 30px;
}
.selected_ {
  border: 1px solid red;
}
.spa {
  display: block;
  background: #f03636 !important;
}
</style>