<template>
  <div class="page_one">
    <div class="level_title self_">
      <span class="B2">Level {{level}}</span>
      <span class="content">
        <span>
          {{ titleList.questionDetail }}
        </span>
      </span>
    </div>
    <div class="contain">
      <div class="left">
        <h2>{{ articleTitle }}</h2>
        <span v-html="textDetile[0]"></span>
      </div>
      <div class="right">
        <div class="item" v-for="(item, i) in titleList.questionList" :key="i">
          <div class="title">
            <div>{{ i + 1 }}</div>
            <span>{{ item.questionText }}</span>
          </div>
          <ul>
            <li>
              <input
                type="text"
                placeholder="Please enter T or F."
                v-model="answerList[i]"
                :maxlength="1"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TorF",
  data() {
    return {
      flag: 0,
      titleList: {},
      article: "",
      text: "",
      textDetile: [],
      articleTitle: "",
      // 试卷答案
      answerList: [],
    };
  },
  mounted() {
    this.getPaperInfo();
  },
  methods: {
    select(i) {
      this.flag = i;
    },
    getPaperInfo() {
      setTimeout(() => {
        let result = this.paperData;
        this.titleList = result.readingP1;
        console.log('====================================');
        console.log(this.titleList.article);
        console.log('====================================');
        this.articleTitle = this.titleList.article.split("#")[0];
        this.article = this.titleList.article.split("#");
        this.article.forEach((item, i) => {
          if (i >= 1) {
            this.text += item + "<br><br>";
          }
          // console.log(this.text);
          this.textDetile = this.text.split("$");
        });
        // console.log(this.articleTitle,this.textDetile );
        this.titleList.questionList.forEach(() => {
          this.answerList.push("");
        });
      }, 1000);
    },
  },
  computed: {
    ...mapState({
      paperData: (state) => state.home.data,
      level:(state)=>state.home.level,
    }),
  },
  watch: {
    answerList() {
      // console.log(this.answerList);
      let answer = [...this.answerList]
     this.$store.dispatch("getAnswerRedOne", answer);
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page_one {
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
}

.self_ {
  margin-bottom: 30px;
}
.level_title {
  width: 100%;
  height: 50px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    height: 50px;
    color: snow;
    text-align: center;
  }

  .B2 {
    width: 10%;
    line-height: 50px;
    background-color: rgb(240, 54, 54);
  }

  .topic_B2 {
    background-color: rgb(51, 51, 51);
    font-size: 20px;
  }

  .content {
    padding: 0 30px;
    width: 90%;
    background-color: rgb(51, 51, 51);
    display: flex;
    //  align-items: center;
    // justify-content: center;
    box-sizing: border-box;
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: start;
    }
  }

  .topic_con {
    background-color: rgb(166, 166, 166);
  }
}

.contain {
  display: flex;
  justify-content: space-between;
  .left {
    width: 55%;
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    h2 {
      text-align: center;
      font-size: 14px;
      margin: 20px 0;
    }
    span {
      font-size: 14px;
      line-height: 24px;
    }
  }
  .right {
    width: 43%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .item {
      width: 95%;
      height: 120px;
      margin-top: 10px;

      .title {
        display: flex;
        div {
          width: 60px;
          height: 60px;
          background-color: #333;
          color: #fff;
          font-size: 18px;
          line-height: 60px;
          text-align: center;
        }
        span {
          flex: 1;
          background-color: #a6a6a6;
          height: 60px;
          color: #fff;
          box-sizing: border-box;
          padding: 5px;
          display: flex;
          align-items: center;
          line-height: 20px;
          font-size: 14px;
        }
      }
      ul {
        height: 250px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        gap: 20px;
        li {
          display: flex;
          width: 100%;

          height: 40px;
          box-sizing: border-box;
          padding-left: 50px;

          input {
            width: 100%;
            box-sizing: border-box;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
</style>