<!-- 写作writer -->

<template>
  <div class="swiper">
    <div class="page_one">
      <div class="level_title self_">
        <span v-if="this.level=='KET'" class="B2">A2 Key</span>
        <span v-else class="B2">Level {{ level }}</span>
        <span class="content">
          <span> </span>
        </span>
      </div>

      <div>
        <!-- <div class="level_title topic"></div> -->
        <div class="anwser">
          <li class="writer_instruction">
            <span v-for="(item, index) in questionDetail" :key="index">
              {{ item }}
            </span>
            <!-- <span>Mention:</span>
            <span>• why cannot attend the meeting</span>
            <span>• one way of stopping the problem</span>
            <span
              >• your thanks for the work she is doing to reduce plastic</span
            >
            <span
              >Write between 100 and 150 words. Do not write an address.</span
            > -->
          </li>
          <li class="writer_topic">
            <img :src="writingP1.questionUrl" alt="" />
            <!-- <img src="A1/Paper1/Images/C1-Paper3-WP1.jpg" alt="" /> -->
          </li>
        </div>
      </div>

      <!-- 用户写作的地方 -->
      <div class="user_writer">
        <Toolbar
          style="border-bottom: 1px solid #ccc; margin-top: 0"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="height: 500px; margin-top: 0"
          v-model="html"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
// 富文本编辑器
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "PaperFour",
  // 注册组件
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: {
        /* 工具栏配置 */
        toolbarKeys: ["bold", "italic", "underline"],
      },
      editorConfig: { placeholder: "Plase enter there",maxLength:5000 },
      mode: "default", // or 'simple'
      writingP1: {
        questionDetail: [],
        questionUrl: "",
        haveImg: "",
        id: "",
      },
      questionDetail: [],
    };
  },
  mounted(){
    // 挂载时获取
    this.getPaperInfo()
  },
  methods: {
    // 创建富文本
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    getPaperInfo(){
      setTimeout(() => {
      this.writingP1 = this.paperData.writingP1
      this.questionDetail = this.writingP1.questionDetail.split('#')
      },1000)
    }

  },
  computed: {
    ...mapState({
      paperData: (state) => state.home.data,
      level: (state) => state.home.level,
    }),
  },
  watch:{

    html(){
      // 收集考生答案
      this.$store.dispatch('getAnswerWitOne',this.html)
      
    }
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page_one {
  width: 100%;
  margin: 0 auto;
}

.self_ {
  margin-bottom: 30px;
}

.level_title {
  width: 100%;
  height: 50px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: start;

  span {
    height: 50px;
    color: snow;
    text-align: center;
  }

  .B2 {
    width: 10%;
    line-height: 50px;
    background-color: rgb(240, 54, 54);
    // font-size: 20px;
  }

  .content {
    padding: 0 30px;
    width: 90%;
    background-color: rgb(51, 51, 51);
    display: flex;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: start;
    }
  }
}

.topic {
  border: 1px solid rgb(229, 224, 224);
}

div {
  margin-top: 25px;

  .anwser {
    border: 1px solid rgb(229, 224, 224);
    // padding-left: 60px;
    margin-top: 25px;

    .writer_instruction {
      width: 55%;
      margin: 20px auto;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      display: flex;
      flex-direction: column;
      align-items: start;

      span {
      }
    }

    .writer_topic {
      img {
        display: block;
        margin: 10px auto;
        width: 80%;
      }
    }
  }
}

.user_writer {
  width: 100%;
  // height: 50px;
  border: 1px solid rgb(30, 29, 29);
}
</style>