<template>
    <div class="page_one">
      <div class="level_title self_">
        <span class="B2">A2 Key</span>
        <span class="content">
          <span>
            {{ titleList.questionDetail }}
          </span>
        </span>
      </div>
      <div class="contain">
        <!-- 题目 -->
        
        <div  v-for="(item, index) in titleList.questionList" :key="index">
          <div class="level_title topic">
          <span class="B2 topic_B2">{{index+1}}</span>
          <span class="content topic_con"></span>
        </div>
          <div class="box">
            <div class="title">
            <img :src="item.questionUrl" alt="" />
          </div>
          <div class="option">
            <ul>
              <li class="li_style"  @click="handleAnswerSelect(item,index, 'A')"
              :class="{ active: answer[index] === 'A' }">
                <div class="select_center" style="font-size:20px;">A</div>
                <span>{{ item.optionAText }}</span>
              </li>
              <li class="li_style" @click="handleAnswerSelect(item,index, 'B')"
              :class="{ active: answer[index] === 'B' }">
                <div class="select_center" style="font-size:20px;">B</div>
                <span>{{ item.optionBText }}</span>
              </li>
              <li class="li_style" @click="handleAnswerSelect(item,index, 'C')"
              :class="{ active: answer[index] === 'C' }">
                <div class="select_center" style="font-size:20px;">C</div>
                <span>{{ item.optionCText }}</span>
              </li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    name: "PaperReading1New",
    data() {
      return {
        list: [
          { left: "A", right: "at" },
          { left: "B", right: "in" },
          { left: "C", right: "on" },
        ],
        flag: "",
        // 存放试卷信息
        titleList: {
              haveOptionsImg: "",
              haveOptionsText: "",
              haveQuestionText: "",
              id: "",
              optionAText: "",
              optionAUrl: "",
              optionBText: "",
              optionBUrl: "",
              optionCText: "",
              optionCUrl: "",
              optionsNumber: "",
              questionText: "",
              questionList:[]
            },
        answer: {}, // 用于存储用户的答案
        answerList:[]
      };
    },
    mounted() {
      this.getPaperInfo();
    },
    methods: {
      // 选择ABC后更改样式
      select(op) {
        this.flag = op.right;
      },
      // 获取试卷信息
      getPaperInfo() {
        setTimeout(() => {
          let result = this.paperData;
          // console.log(result);
  
            this.titleList = result.readingP1;
            this.titleList.questionList.forEach((item,i)=>{
            this.answerList[i] = ''
          })
            // console.log(this.titleList);
   
        }, 1000);
      },
      handleAnswerSelect(item,index,answer){
          this.activeIndex = index;
          this.$set(this.answer, index, answer);
          // console.log(this.answer)
      }
    },
    computed: {
      ...mapState({
        paperData: (state) => state.home.data,
        level:(state)=>state.home.level,
      }),
    },
    watch:{
           // 收集答案
      answer:{
        
        handler(newVal, oldVal) {
          
          // console.log(this.answers);
              for(let info in newVal){
                if(newVal[info]){
                  // console.log(newVal[info]);
                  this.answerList[info] = newVal[info]
                }else{
                  this.answerList[info] = ''
                }
              }
              // console.log(answerList);
              let answers = [...this.answerList]
              this.$store.dispatch('getAnswerRedOne',answers)
              console.log(answers);
  
          },
          deep: true,
  
      }
    }
  };
  </script>
  
  <style lang="less" scoped>
  * {
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box !important;
  }
  
  .page_one {
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
  }
  
  .self_ {
    margin-bottom: 30px;
  }
  .level_title {
    width: 100%;
    height: 50px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    margin-top: 20px;
  
    span {
      height: 50px;
      color: snow;
      text-align: center;
    }
  
    .B2 {
      width: 10%;
      line-height: 50px;
      background-color: rgb(240, 54, 54);
    }
  
    .topic_B2 {
      background-color: rgb(51, 51, 51);
      font-size: 20px;
    }
  
    .content {
      padding: 0 30px;
      width: 90%;
      background-color: rgb(51, 51, 51);
      display: flex;
      align-items: center;
      // justify-content: center;
      box-sizing: border-box;
      span {
        display: flex;
        align-items: center;
        font-size: 14px;
        text-align: start;
      }
    }
    .topic_con {
      background-color: rgb(166, 166, 166);
      
    }
  }
  .contain {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .box {
      height: 250px;
      width: 100%;
      margin: 0 auto;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      display: flex;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        width: 600px;
        justify-content: center;
        img {
          height: 95%;
          max-width: 600px;
        }
      }
      .option {
        width: 560px;
        height: 100%;
        margin-right: 5px;
        ul {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-around;
          box-sizing: border-box;
          li {
            display: flex;
            height: 45px;
            justify-content: center;
            
          //   line-height: 45px;
            box-sizing: border-box;
            div {
              width: 50px;
              background-color: #333;
              color: white;
              text-align: center;
              box-sizing: border-box;
            }
            .select_center{
              display: flex;
              align-items: center;
              justify-content: center;
            }
            span {
              background-color: #fff;
              border: 1px solid #000;
              flex: 1;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              border-left: none;
              padding-left: 10px;
            }
          }
          .active {
            
            border: 1px solid rgb(240, 54, 54);
            box-sizing: border-box;
            div {
              background-color: rgb(240, 54, 54);
              box-sizing: border-box;
            }
            span {
              border: none;
              background-color: #fff2f2;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
  </style>